import { Injectable } from "@angular/core";
import { NgxIndexedDBService, WithID } from "ngx-indexed-db";
import {
  DecryptorPipe,
  encrypt,
  decrypt,
  decryptPermissions,
  EncryptorPipe,
} from "../../pipes/encryptors";
import { keyBy } from "lodash";
import { promise } from "protractor";
import { CryptoService } from "../encryption/crypto.service";
import { DataStore } from "aws-amplify";

@Injectable({
  providedIn: "root",
})
export class AcpIndexDBService {
  constructor(
    private dbService: NgxIndexedDBService,
    private cryptoService: CryptoService
  ) {}

  //Insert List
  public async processToIdbList(tableName, res, user_id, column_name) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find((entry) => entry.id === user_id);

    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
    }
    const dataPush: any[] = [];
    for (const entry of res) {
      const encryptedData = await encrypt(entry);
      dataPush.push(encryptedData);
    }
    const dataPass = {
      id: user_id,
      [column_name]: dataPush,
    };
    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedEntriesList(
    tableName: string,
    user_id,
    column_name
  ): Promise<any[]> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const userEntry = entries.find((entry) => entry.id === user_id);

      if (!userEntry) {
        return [];
      }

      const decryptedResults = await Promise.all(
        userEntry[column_name].map((entry) => decrypt(entry))
      );

      return decryptedResults;
    } catch (error) {
      console.error(
        `Error fetching decrypted entries from ${tableName}:`,
        error
      );
      return [];
    }
  }

  //Insert an id and encrypted object, also delete only the user_id given
  public async processToIDB(
    tableName,
    res,
    encryptFields = [],
    user_id,
    column_name
  ) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find((entry) => entry.id === user_id);

    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
    }

    const datapush = [];
    for (const item of res) {
      const dataToStore = {};
      for (const [key, value] of Object.entries(item)) {
        if (encryptFields.includes(key)) {
          dataToStore[key] =
            value !== null ? await encrypt(String(value)) : null;
        } else {
          dataToStore[key] = value;
        }
      }
      datapush.push(dataToStore);
    }

    const dataPass = {
      id: user_id,
      [column_name]: datapush,
    };
    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedEntries(
    tableName,
    decryptFields,
    user_id,
    column_name
  ): Promise<any[]> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const decryptFieldSet = new Set(decryptFields);

      const userEntriesMap = new Map(entries.map((entry) => [entry.id, entry]));
      const userEntry = userEntriesMap.get(user_id);
      if (!userEntry) {
        return [];
      }
      const decryptedResults = await Promise.all(
        userEntry[column_name].map(async (entry: any) => {
          const decryptedEntry: any = {};
          await Promise.all(
            Object.entries(entry).map(async ([key, value]) => {
              if (decryptFieldSet.has(key)) {
                decryptedEntry[key] =
                  value !== null ? await decrypt(value as ArrayBuffer) : null;
              }
            })
          );
          return decryptedEntry;
        })
      );
      return decryptedResults;
    } catch (error) {
      console.error(
        `Error fetching decrypted entries from ${tableName}:`,
        error
      );
      return [];
    }
  }

  //Encrypt all
  public async processToIdbV2(tableName, res, encryptFields = []) {
    await this.dbService.clear(tableName).toPromise();
    for (const item of res) {
      const dataToStore = {};
      for (const [key, value] of Object.entries(item)) {
        if (encryptFields.includes(key)) {
          dataToStore[key] =
            value !== null ? await encrypt(String(value)) : null;
        }
      }
      try {
        const key = await this.dbService
          .add(tableName, dataToStore)
          .toPromise();
      } catch (error) {
        console.log(`Error adding item to ${tableName}:`, error);
      }
    }
  }

  //Decrypt All
  public async getDecryptedEntriesV2(
    tableName: string,
    decryptFields: string[]
  ): Promise<any[]> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();

      const decryptedResults = await Promise.all(
        entries.map(async (entry) => {
          const decryptedEntry: any = {};

          // Decrypt only the specified fields, but retain all fields
          await Promise.all(
            Object.entries(entry).map(async ([key, value]) => {
              if (decryptFields.includes(key)) {
                decryptedEntry[key] =
                  value !== null ? await decrypt(value as ArrayBuffer) : null;
              }
            })
          );

          return decryptedEntry;
        })
      );

      return decryptedResults;
    } catch (error) {
      console.error(
        `Error fetching decrypted entries from ${tableName}:`,
        error
      );
      return [];
    }
  }

  //For single Objects
  public async processToIdbV3(tableName, res, encryptFields = []) {
    await this.dbService.clear(tableName).toPromise();

    const dataToStore = {};
    for (const [key, value] of Object.entries(res)) {
      if (encryptFields.includes(key)) {
        dataToStore[key] = value !== null ? await encrypt(String(value)) : null;
        // dataToStore[key] = value
      }
    }
    try {
      const key = await this.dbService.add(tableName, dataToStore).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedEntriesV3(
    tableName: string,
    decryptFields: string[]
  ): Promise<any> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      if (entries.length === 0) {
        return {};
      }

      const firstEntry = entries[0];
      const decryptedEntry = await Promise.all(
        Object.entries(firstEntry).map(async ([key, value]) => {
          if (decryptFields.includes(key)) {
            return [
              key,
              value !== null ? await decrypt(value as ArrayBuffer) : null,
            ];
          }
        })
      );

      return Object.fromEntries(decryptedEntry);
    } catch (error) {
      console.error(
        `Error fetching or decrypting entries from ${tableName}:`,
        error
      );
      return {};
    }
  }

  public async processToIdbPermissions(tableName, res, encryptFields = []) {
    await this.dbService.clear(tableName).toPromise();
    for (const item of res) {
      const dataToStore = {};
      for (const [key, value] of Object.entries(item)) {
        if (encryptFields.includes(key)) {
          if (
            value !== null &&
            typeof value === "object" &&
            !Array.isArray(value)
          ) {
            dataToStore[key] = {};
            for (const [nestedKey, nestedValue] of Object.entries(value)) {
              dataToStore[key][nestedKey] = await encrypt(String(nestedValue));
            }
          } else {
            dataToStore[key] = await encrypt(String(value));
          }
        }
      }
      try {
        const key = await this.dbService
          .add(tableName, dataToStore)
          .toPromise();
      } catch (error) {
        console.log(`Error adding item to ${tableName}:`, error);
      }
    }
  }

  public async processToIdbDoctorProducts(
    tableName,
    res,
    encryptFields = [],
    user_id,
    column_name
  ) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find((entry) => entry.id === user_id);

    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
    }
    const datapush = [];
    for (const item of res) {
      const dataToStore = [];
      for (const [key, value] of Object.entries(item)) {
        if (encryptFields.includes(key)) {
          if (Array.isArray(value)) {
            dataToStore[key] = [];
            for (const [nestedKey, nestedValue] of Object.entries(value)) {
              if (
                nestedValue !== null &&
                typeof nestedValue === "object" &&
                !Array.isArray(nestedValue)
              ) {
                dataToStore[key][nestedKey] = {};
                for (const [lastKey, lastValue] of Object.entries(
                  nestedValue
                )) {
                  dataToStore[key][nestedKey][lastKey] = await encrypt(
                    String(lastValue)
                  );
                }
              }
            }
          } else {
            dataToStore[key] = value;
          }
        }
      }
      datapush.push(dataToStore);
    }
    const dataPass = {
      id: user_id,
      [column_name]: datapush,
    };
    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedEntriesDoctorProductsSingle(
    tableName,
    user_id,
    column_name,
    doctor_id
  ): Promise<any> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();

      const userEntriesMap = new Map(entries.map((entry) => [entry.id, entry]));
      const userEntry = userEntriesMap.get(user_id);
      if (!userEntry) {
        return {};
      }

      const doctorEntriesMap = new Map(
        userEntry[column_name].map((entry) => [entry.doctor_id, entry])
      );
      const doctorEntry = doctorEntriesMap.get(doctor_id);
      if (!doctorEntry) {
        return {};
      }

      const decryptedResults = await Promise.all(
        doctorEntry["output"].map(async (entry: any) => {
          const decryptedEntry: any = {};
          await Promise.all(
            Object.entries(entry).map(async ([key, value]) => {
              decryptedEntry[key] =
                value !== null ? await decrypt(value as ArrayBuffer) : null;
            })
          );
          return decryptedEntry;
        })
      );
      return decryptedResults;
    } catch (error) {
      console.error(
        `Error fetching decrypted entries from ${tableName}:`,
        error
      );
      return {};
    }
  }

  public async getDecryptedEntriesDoctorProducts(
    tableName,
    user_id,
    decryptFields = [],
    column_name
  ): Promise<any> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const userEntriesMap = new Map(entries.map((entry) => [entry.id, entry]));
      const userEntry = userEntriesMap.get(user_id);
      if (!userEntry) {
        return {};
      }

      const dataToDecrypt = userEntry[column_name];
      const decryptedData = [];
      await Promise.all(
        dataToDecrypt.map(async (item) => {
          const decryptedItem = {};

          await Promise.all(
            Object.entries(item).map(async ([key, value]) => {
              if (decryptFields.includes(key)) {
                if (Array.isArray(value)) {
                  decryptedItem[key] = await Promise.all(
                    value.map(async (nestedValue) => {
                      if (
                        nestedValue !== null &&
                        typeof nestedValue === "object" &&
                        !Array.isArray(nestedValue)
                      ) {
                        const decryptedObj = {};
                        await Promise.all(
                          Object.entries(nestedValue).map(
                            async ([lastKey, lastValue]) => {
                              decryptedObj[lastKey] =
                                lastValue !== null
                                  ? await decrypt(lastValue as ArrayBuffer)
                                  : null;
                            }
                          )
                        );
                        return decryptedObj;
                      }
                      return nestedValue;
                    })
                  );
                } else {
                  decryptedItem[key] = value !== null ? value : null;
                }
              } else {
                decryptedItem[key] = value;
              }
            })
          );

          decryptedData.push(decryptedItem);
        })
      );

      return decryptedData;
    } catch (error) {
      console.error(
        `Error fetching decrypted entries from ${tableName}:`,
        error
      );
      return {};
    }
  }

  public async getDecryptedEntriesPermissions(
    tableName: string,
    decryptFields: string[]
  ): Promise<any[]> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const decryptedResults = await Promise.all(
        entries.map(async (entry) => {
          const decryptedEntry: any = {};
          await Promise.all(
            Object.entries(entry).map(async ([key, value]) => {
              if (
                decryptFields.includes(key) &&
                value !== null &&
                typeof value === "object" &&
                !Array.isArray(value)
              ) {
                decryptedEntry[key] = await decryptPermissions(
                  value as ArrayBuffer
                );
                if (decryptedEntry[key] == "") {
                  decryptedEntry[key] = {};
                  await Promise.all(
                    Object.entries(value).map(
                      async ([nestedKey, nestedValue]) => {
                        decryptedEntry[key][nestedKey] =
                          nestedValue !== null
                            ? await decrypt(nestedValue as ArrayBuffer)
                            : null;
                      }
                    )
                  );
                }
              }
            })
          );
          return decryptedEntry;
        })
      );
      return decryptedResults;
    } catch (error) {
      console.error(
        `Error fetching decrypted entries from ${tableName}:`,
        error
      );
      return [];
    }
  }

  public async processToIdbIncidental(
    tableName,
    res,
    encryptFields = [],
    user_id,
    column_name
  ) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = await entries.find((entry) => entry.id === user_id);
    const datapush = [];
    const dataToStore = {};
    for (const [key, value] of Object.entries(res)) {
      if (encryptFields.includes(key)) {
        dataToStore[key] = value !== null ? await encrypt(String(value)) : null;
      } else {
        dataToStore[key] = value;
      }
    }
    datapush.push(dataToStore);
    const dataPass = {
      id: user_id,
      [column_name]: datapush,
    };

    try {
      if (existingEntry) {
        const existingData = existingEntry[column_name] || [];
        existingData.push(...datapush);
        const updatedEntry = {
          ...existingEntry,
          [column_name]: existingData,
        };
        await this.dbService.update(tableName, updatedEntry).toPromise();
      } else {
        await this.dbService.add(tableName, dataPass).toPromise();
      }
    } catch (error) {
      console.log(`Error adding/updating item in ${tableName}:`, error);
    }
  }

  public async processToIdbProducts(tableName, res, encryptFields = []) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    if (tableName == "acp_products") {
      const matchingEntries = entries.filter((entry) => {
        const doctorId = entry[0].doctor_id;
        const acpDate = entry[0].acp_date;
        return doctorId == res.doctor_id && acpDate == res.acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }

    if (tableName == "acp_products_planned") {
      const matchingEntries = entries.filter((entry) => {
        const id = entry[0].id;
        const acpDate = entry[0].acp_date;
        return id == res.id && acpDate == res.acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }

    const datapush = [];
    const dataObject = {};
    for (const [key, value] of Object.entries(res)) {
      if (encryptFields.includes(key)) {
        if (Array.isArray(value)) {
          dataObject[key] = [];
          for (const [nestedKey, nestedValue] of Object.entries(value)) {
            if (
              nestedValue !== null &&
              typeof nestedValue === "object" &&
              !Array.isArray(nestedValue)
            ) {
              dataObject[key][nestedKey] = {};
              for (const [lastKey, lastValue] of Object.entries(nestedValue)) {
                dataObject[key][nestedKey][lastKey] = await encrypt(
                  String(lastValue)
                );
              }
            }
          }
        } else {
          dataObject[key] = value;
        }
      }
    }
    datapush.push(dataObject);
    try {
      const key = await this.dbService.add(tableName, datapush).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedEntriesAllProductNotes(
    tableName,
    decryptFields
  ): Promise<any[]> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const decryptedData: any[] = [];

      // Decrypt data for all entries
      await Promise.all(
        entries.map(async (item) => {
          const decryptedItem: any = {};
          await Promise.all(
            Object.entries(item).map(async ([key, value]) => {
              if (
                value !== null &&
                typeof value === "object" &&
                !Array.isArray(value)
              ) {
                await Promise.all(
                  Object.entries(value).map(
                    async ([nestedKey, nestedValue]) => {
                      if (decryptFields.includes(nestedKey)) {
                        if (Array.isArray(nestedValue)) {
                          // Handle array of objects
                          decryptedItem[nestedKey] = await Promise.all(
                            nestedValue.map(async (lastItem) => {
                              if (
                                lastItem !== null &&
                                typeof lastItem === "object" &&
                                !Array.isArray(lastItem)
                              ) {
                                const decryptedLastItem: any = {};
                                await Promise.all(
                                  Object.entries(lastItem).map(
                                    async ([finalKey, finalValue]) => {
                                      decryptedLastItem[finalKey] =
                                        finalValue !== null
                                          ? await decrypt(
                                              finalValue as ArrayBuffer
                                            )
                                          : null;
                                    }
                                  )
                                );
                                return decryptedLastItem; // Return decrypted last item
                              }
                              return lastItem; // If not an object, return as is
                            })
                          );
                        } else {
                          // Handle single value
                          decryptedItem[nestedKey] = nestedValue;
                        }
                      }
                    }
                  )
                );
              }
            })
          );
          decryptedData.push(decryptedItem); // Push the decrypted item to the array
        })
      );

      return decryptedData; // Return the decrypted data
    } catch (error) {
      console.error(
        `Error fetching decrypted entries from ${tableName}:`,
        error
      );
      return [];
    }
  }

  public async getDecryptedEntriesProductNotes(
    tableName,
    doctor_id,
    acp_date,
    decryptFields
  ): Promise<any> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      let matchingEntries;
      if (tableName == "acp_products" || tableName == "acp_notes") {
        matchingEntries = entries.filter((entry) => {
          const doctorId = entry[0].doctor_id;
          const acpDate = entry[0].acp_date;
          return doctorId == doctor_id && acpDate == acp_date;
        });
      } else {
        matchingEntries = entries.filter((entry) => {
          const doctorId = entry[0].id;
          const acpDate = entry[0].acp_date;
          return doctorId == doctor_id && acpDate == acp_date;
        });
      }

      if (!matchingEntries) {
        return {};
      }

      if (!matchingEntries[0]) {
        return {};
      }

      const decryptedData = [];
      await Promise.all(
        matchingEntries[0].map(async (item) => {
          const decryptedItem = {};
          await Promise.all(
            Object.entries(item).map(async ([key, value]) => {
              if (decryptFields.includes(key)) {
                if (Array.isArray(value)) {
                  decryptedItem[key] = await Promise.all(
                    value.map(async (nestedValue) => {
                      if (
                        nestedValue !== null &&
                        typeof nestedValue === "object" &&
                        !Array.isArray(nestedValue)
                      ) {
                        const decryptedObj = {};
                        await Promise.all(
                          Object.entries(nestedValue).map(
                            async ([lastKey, lastValue]) => {
                              decryptedObj[lastKey] =
                                lastValue !== null
                                  ? await decrypt(lastValue as ArrayBuffer)
                                  : null;
                            }
                          )
                        );
                        return decryptedObj;
                      }
                      return nestedValue;
                    })
                  );
                } else {
                  decryptedItem[key] = value;
                }
              }
            })
          );
          decryptedData.push(decryptedItem);
        })
      );
      return decryptedData;
    } catch (error) {
      console.error(
        `Error fetching decrypted entries from ${tableName}:`,
        error
      );
      return {};
    }
  }

  public async processToIdbNotes(tableName, res, encryptFields = []) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    if (tableName == "acp_notes") {
      const matchingEntries = entries.filter((entry) => {
        const doctorId = entry[0].doctor_id;
        const acpDate = entry[0].acp_date;
        return doctorId == res.doctor_id && acpDate == res.acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    } else {
      const matchingEntries = entries.filter((entry) => {
        const id = entry[0].id;
        const acpDate = entry[0].acp_date;
        return id == res.id && acpDate == res.acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }

    const datapush = [];
    const dataObject = {};
    for (const [key, value] of Object.entries(res)) {
      if (encryptFields.includes(key)) {
        if (Array.isArray(value)) {
          dataObject[key] = [];
          for (const [nestedKey, nestedValue] of Object.entries(value)) {
            if (
              nestedValue !== null &&
              typeof nestedValue === "object" &&
              !Array.isArray(nestedValue)
            ) {
              dataObject[key][nestedKey] = {};
              for (const [lastKey, lastValue] of Object.entries(nestedValue)) {
                dataObject[key][nestedKey][lastKey] = await encrypt(
                  String(lastValue)
                );
              }
            }
          }
        } else {
          dataObject[key] = value;
        }
      }
    }
    datapush.push(dataObject);
    try {
      const key = await this.dbService.add(tableName, datapush).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async saveToIDB(
    tableName: string,
    newData: any[],
    user_id: number,
    column_name
  ) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find((entry) => entry.id === user_id);
    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
    }

    const datapush = [];
    for (const item of newData) {
      const dataToStore = {};
      const itemImage = item.imageFile;
      const trylang = JSON.stringify(item);
      dataToStore["data"] = await this.cryptoService.encryptDataBased(trylang);
      dataToStore["imageFile"] = itemImage;
      datapush.push(dataToStore);
    }

    const dataPass = {
      id: user_id,
      [column_name]: datapush,
    };

    // const datapush = [];
    // for (const item of newData) {
    //   const dataToStore = {};
    //   for (const [key, value] of Object.entries(item)) {
    //     if (Array.isArray(value)) {
    //       dataToStore[key] = await Promise.all(value.map(async (nestedItem) => {
    //         const processedNestedItem = {};
    //         for (const [nestedKey, nestedValue] of Object.entries(nestedItem)) {
    //           processedNestedItem[nestedKey] = nestedValue !== null ? await encrypt(String(nestedValue)) : null;
    //         }
    //         return processedNestedItem;
    //       }));
    //     } else if (typeof value === 'object' && value !== null) {
    //       const processedObject = {};
    //       for (const [nestedKey, nestedValue] of Object.entries(value)) {
    //         processedObject[nestedKey] = nestedValue !== null ? await encrypt(String(nestedValue)) : null;
    //       }
    //       dataToStore[key] = processedObject;
    //     } else {
    //       dataToStore[key] = value;
    //     }
    //   }
    //   dataToStore['imageFile'] = imageFile
    //   datapush.push(dataToStore);
    // }

    // const dataPass = {
    //   id: user_id,
    //   [column_name]: datapush,
    // };

    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async loadFromIDB(
    tableName: string,
    user_id: number,
    column_name: string
  ) {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const existingEntry = entries.find((entry) => entry.id === user_id);

      if (!existingEntry || !existingEntry[column_name]) {
        return [];
      }
      const decryptedData = [];
      for (const item of existingEntry[column_name]) {
        const itemToReturn = {};
        for (const [key, value] of Object.entries(item)) {
          if (key == "imageFile") {
            itemToReturn["imageFile"] = value;
          } else if (key == "data") {
            const jsonParse = JSON.parse(
              await this.cryptoService.decryptDataBased(String(value))
            );
            for (const [jsonKey, jsonValue] of Object.entries(jsonParse)) {
              itemToReturn[jsonKey] = jsonValue;
            }
          }
        }
        decryptedData.push(itemToReturn);
      }

      // const decryptedData = [];
      // for (const item of existingEntry[column_name]) {
      //   const dataToLoad = {};

      //   for (const [key, value] of Object.entries(item)) {
      //     if (Array.isArray(value)) {
      //       dataToLoad[key] = await Promise.all(value.map(async (nestedItem) => {
      //         const decryptedNestedItem = {};
      //         for (const [nestedKey, nestedValue] of Object.entries(nestedItem)) {
      //           decryptedNestedItem[nestedKey] = nestedValue !== null ? await decrypt(nestedValue as ArrayBuffer) : null;
      //         }
      //         return decryptedNestedItem;
      //       }));
      //     } else if (typeof value === 'object' && value !== null) {
      //       if (key === 'imageFile') {
      //         // Return imageFile as-is
      //         dataToLoad[key] = value;
      //       } else {
      //         const decryptedObject = {};
      //         for (const [nestedKey, nestedValue] of Object.entries(value)) {
      //           decryptedObject[nestedKey] = nestedValue !== null ? await decrypt(nestedValue as ArrayBuffer) : null;
      //         }
      //         dataToLoad[key] = decryptedObject;
      //       }
      //     } else {
      //       dataToLoad[key] = value;
      //     }
      //   }

      //   decryptedData.push(dataToLoad);
      // }

      return decryptedData;
    } catch (error) {
      console.log(`Error loading data from ${tableName}:`, error);
      return null;
    }
  }

  public async deleteAcpProductNotes(tableName, doctor_id, acp_date) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    if (tableName == "acp_products" || tableName == "acp_notes") {
      const matchingEntries = entries.filter((entry) => {
        const doctorId = entry[0].doctor_id;
        const acpDate = entry[0].acp_date;
        return doctorId == doctor_id && acpDate == acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    } else {
      const matchingEntries = entries.filter((entry) => {
        const id = entry[0].id;
        const acpDate = entry[0].acp_date;
        return id == doctor_id && acpDate == acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }
  }

  public async deleteAcpProductNotesId(tableName, doctor_id) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    if (tableName == "acp_products" || tableName == "acp_notes") {
      const matchingEntries = entries.filter((entry) => {
        const doctorId = entry[0].doctor_id;
        return doctorId == doctor_id;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    } else {
      const matchingEntries = entries.filter((entry) => {
        const id = entry[0].id;
        return id == doctor_id;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }
  }

  public async deleteActivityDetailbyId(tableName, act_id, acp_date) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const matchingEntries = entries.filter((entry) => {
      const id = entry[0].id;
      const acpDate = entry[0].acp_date;
      return id == act_id && acpDate == acp_date;
    });
    for (const entry of matchingEntries) {
      const entryId = entry.id;
      await this.dbService.delete(tableName, entryId).toPromise();
    }
  }

  public async processToIdbActivityDetail(tableName, res, encryptFields = []) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();

    const matchingEntries = entries.filter((entry) => {
      const id = entry[0].id;
      const acpDate = entry[0].acp_date;
      return id == res.id && acpDate == res.acp_date;
    });
    for (const entry of matchingEntries) {
      const entryId = entry.id;
      await this.dbService.delete(tableName, entryId).toPromise();
    }

    const datapush = [];
    const dataObject = {};
    for (const [key, value] of Object.entries(res)) {
      if (encryptFields.includes(key)) {
        dataObject[key] = await encrypt(String(value));
      } else {
        dataObject[key] = value;
      }
    }
    datapush.push(dataObject);

    try {
      const key = await this.dbService.add(tableName, datapush).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedEntriesActivityDetail(
    tableName,
    decryptFields
  ): Promise<any> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const decryptedData: any[] = [];

      // Decrypt data for all entries
      await Promise.all(
        entries.map(async (item) => {
          const decryptedItem: any = {};
          await Promise.all(
            Object.entries(item).map(async ([key, value]) => {
              if (
                value !== null &&
                typeof value === "object" &&
                !Array.isArray(value)
              ) {
                await Promise.all(
                  Object.entries(value).map(
                    async ([nestedKey, nestedValue]) => {
                      if (decryptFields.includes(nestedKey)) {
                        decryptedItem[nestedKey] = await decrypt(
                          nestedValue as ArrayBuffer
                        );
                      } else {
                        decryptedItem[nestedKey] = nestedValue;
                      }
                    }
                  )
                );
              }
            })
          );
          decryptedData.push(decryptedItem);
        })
      );

      return decryptedData;
    } catch (error) {
      console.error(
        `Error fetching decrypted entries from ${tableName}:`,
        error
      );
      return [];
    }
  }

  public async deleteActivityDetail(tableName, res) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const matchingEntries = entries.filter((entry) => {
      const id = entry[0].id;
      const acpDate = entry[0].acp_date;
      return id == res.id && acpDate == res.acp_date;
    });
    for (const entry of matchingEntries) {
      const entryId = entry.id;
      await this.dbService.delete(tableName, entryId).toPromise();
    }
  }

  // THIS ARE THE AES ENCRYPTIONS!

  public async processAESToIDB(tableName, res, user_id, column_name) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find((entry) => entry.id === user_id);

    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
    }

    if (res.length == 0) {
      return [];
    }

    const testTO = JSON.stringify(res);
    // const testEncrypted = await encrypt(String(res))
    const testEncrypted = await this.cryptoService.encryptDataBased(testTO);
    const dataPass = {
      id: user_id,
      [column_name]: testEncrypted,
    };

    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async processAEStoIDBwithCustomer(
    tableName,
    res,
    encryptFields = [],
    user_id,
    customer_id
  ) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();

    const matchingEntries = entries.filter((entry) => {
      const id = entry[0].id;
      const customerId = entry[0].customer_id;
      return id == user_id && customerId == customer_id;
    });
    for (const entry of matchingEntries) {
      const entryId = entry.id;
      await this.dbService.delete(tableName, entryId).toPromise();
    }

    const datapush = [];
    const dataObject = {};
    for (const [key, value] of Object.entries(res)) {
      if (encryptFields.includes(key)) {
        if (Array.isArray(value)) {
          dataObject[key] = [];
          for (const [nestedKey, nestedValue] of Object.entries(value)) {
            if (
              nestedValue !== null &&
              typeof nestedValue === "object" &&
              !Array.isArray(nestedValue)
            ) {
              dataObject[key][nestedKey] = {};
              for (const [lastKey, lastValue] of Object.entries(nestedValue)) {
                dataObject[key][nestedKey][lastKey] = await encrypt(
                  String(lastValue)
                );
              }
            }
          }
        } else {
          dataObject[key] = value;
        }
      }
    }
    datapush.push(dataObject);
    try {
      const key = await this.dbService.add(tableName, datapush).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  // public async processAEStoIDBwithCustomer1(tableName, res, user_id, column_name, customer_id) {
  //   const entries: any[] = await this.dbService.getAll(tableName).toPromise();
  //   const existingEntry = entries.find(entry => entry.id === user_id && entry.customer_id === customer_id);

  //   if (existingEntry) {
  //     await this.dbService.delete(tableName, user_id).toPromise();
  //   }

  //   if (res.length == 0) {
  //     return []
  //   }

  //   const testTO = JSON.stringify(res);
  //   // const testEncrypted = await encrypt(String(res))
  //   const testEncrypted = await this.cryptoService.encryptDataBased(testTO);
  //   const dataPass = {
  //     id: user_id,
  //     customer_id: customer_id,
  //     [column_name]: testEncrypted
  //   }

  //   try {
  //     const key = await this.dbService.add(tableName, dataPass).toPromise();
  //   } catch (error) {
  //     console.log(`Error adding item to ${tableName}:`, error);
  //   }
  // }

  public async processAEStoIDBwithCustomer1(
    tableName,
    res,
    user_id,
    column_name,
    customer_id,
    dynamicProperties: any = {}
  ) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();

    // const existingEntry = entries.find(entry => entry.user_id === user_id && entry.customer_id === customer_id);
    const searchCriteria = { user_id, customer_id, ...dynamicProperties };

    const existingEntry = entries.find((entry) =>
      Object.keys(searchCriteria).every(
        (key) => entry[key] === searchCriteria[key]
      )
    );

    if (existingEntry) {
      await this.dbService.delete(tableName, existingEntry.id).toPromise();
    }

    if (res.length === 0) {
      return [];
    }

    const encryptedData = await this.cryptoService.encryptDataBased(
      JSON.stringify(res)
    );

    const dataPass = {
      user_id,
      customer_id,
      ...dynamicProperties,
      [column_name]: encryptedData,
    };

    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.error(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedAesEntries(tableName, user_id, column_name) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const userEntriesMap = new Map(entries.map((entry) => [entry.id, entry]));
    const userEntry = userEntriesMap.get(user_id);
    if (!userEntry || userEntry == undefined) {
      return [];
    }
    if (userEntry[column_name].length <= 0) {
      return [];
    }

    const decryptedData = await this.cryptoService.decryptDataBased(
      userEntry[column_name]
    );
    return JSON.parse(decryptedData);
  }

  public async getDecryptedAesEntriesWithCustomer(
    tableName,
    user_id,
    customer_id,
    column_name,
    dynamicProperties: any = {}
  ) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();

    // const userEntry = entries.find(entry => entry.user_id === user_id && entry.customer_id === customer_id);
    const searchCriteria = { user_id, customer_id, ...dynamicProperties };

    //original working
    // const userEntry = entries.find((entry) =>
    //   Object.keys(searchCriteria).every(
    //     (key) => entry[key] === searchCriteria[key]
    //   )
    // );

    //handles if key is an array object
    const userEntry = entries.find((entry) =>
      Object.keys(searchCriteria).every((key) => {
        const entryValue = entry[key];
        const searchValue = searchCriteria[key];
  
        if (Array.isArray(entryValue)) {
          return entryValue.includes(searchValue);
        }
  
        return entryValue === searchValue;
      })
    );

    if (
      !userEntry ||
      !userEntry[column_name] ||
      userEntry[column_name].length === 0
    ) {
      return [];
    }

    const decryptedData = await this.cryptoService.decryptDataBased(
      userEntry[column_name]
    );

    return JSON.parse(decryptedData);
  }

  public async getDecryptedAesEntriesWithCustomerList(
    tableName: string,
    user_id: any,
    customer_id: any,
    column_name: string,
    dynamicProperties: any = {}
  ) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();

    //for specific customer
    // const searchCriteria = { user_id, customer_id, ...dynamicProperties };

    //getting all matching data when customer id is 0
    const searchCriteria =
      customer_id === 0
        ? { user_id, ...dynamicProperties }
        : { user_id, customer_id, ...dynamicProperties };

    const matchingEntries = entries.filter((entry) =>
      Object.keys(searchCriteria).every(
        (key) => entry[key] === searchCriteria[key]
      )
    );

    if (
      matchingEntries.length === 0 ||
      !matchingEntries.every(
        (entry) => entry[column_name] && entry[column_name].length > 0
      )
    ) {
      return [];
    }
    const decryptedDataList = [];
    for (const entry of matchingEntries) {
      const decryptedData = await this.cryptoService.decryptDataBased(
        entry[column_name]
      );
      decryptedDataList.push(...JSON.parse(decryptedData));
    }

    return decryptedDataList;
  }

  public async processAESToIDBIncidental(tableName, res, user_id, column_name) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find((entry) => entry.id === user_id);
    let dataExisting = [];
    let toBePush = "";
    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
      let existingData = await this.cryptoService.decryptDataBased(
        existingEntry[column_name]
      );
      dataExisting = JSON.parse(existingData);
      dataExisting.push(res);
      toBePush = await this.cryptoService.encryptDataBased(
        JSON.stringify(dataExisting)
      );
    } else {
      dataExisting.push(res);
      toBePush = await this.cryptoService.encryptDataBased(
        JSON.stringify(dataExisting)
      );
    }

    const dataPass = {
      id: user_id,
      [column_name]: toBePush,
    };

    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async processToIdbProductsAes(tableName, res) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    if (tableName == "acp_products") {
      const matchingEntries = entries.filter((entry) => {
        const doctorId = entry[0].doctor_id;
        const acpDate = entry[0].acp_date;
        return doctorId == res.doctor_id && acpDate == res.acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }

    if (tableName == "acp_products_planned") {
      const matchingEntries = entries.filter((entry) => {
        const id = entry[0].id;
        const acpDate = entry[0].acp_date;
        return id == res.id && acpDate == res.acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }
  }

  public async processAESToIDBwithFile(tableName, res, user_id, column_name) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find(entry => entry.id === user_id);

    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
    }

    if (res.length == 0) {
      return []
    }

    const datapush = [];
    for (const item of res) {
      const dataToStore = {}
      const file = item.file
      const downloaded_file = JSON.stringify(item.downloaded_file)
      dataToStore['downloaded_file'] = await this.cryptoService.encryptDataBased(downloaded_file)
      dataToStore['file'] = file
      datapush.push(dataToStore)
    }

    const dataPass = {
      id: user_id,
      [column_name]: datapush,
    };

    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }
  public async getDecryptedAesEntriesWithFile(tableName, user_id, column_name) {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const existingEntry = entries.find(entry => entry.id === user_id);

      if (!existingEntry || !existingEntry[column_name]) {
        return [];
      }
      const decryptedData = [];
      for (const item of existingEntry[column_name]) {
        const itemToReturn = {}
        for (const [key, value] of Object.entries(item)) {
          if (key == 'file') {
            itemToReturn['file'] = value
          } else if (key == 'downloaded_file') {
            itemToReturn['downloaded_file'] = JSON.parse(await this.cryptoService.decryptDataBased(String(value)))
          }
        }
        decryptedData.push(itemToReturn)
      }

      return decryptedData;

    } catch (error) {
      console.log(`Error loading data from ${tableName}:`, error);
      return null;
    }
  }

  //  NGSW STORE VERSION

  public async storeVersion(tableName, res) {
    const dataPass = {
      id: 1,
      version: res,
    };

    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async updateVersion(tableName: string, res: string) {
    const dataPass = {
      id: 1,
      version: res,
    };

    try {
      const key = await this.dbService.update(tableName, dataPass).toPromise();
      console.log(`Version updated for ${tableName} with key:`, key);
    } catch (error) {
      console.log(`Error updating version for ${tableName}:`, error);
    }
  }

  public async getStoredVersion(): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      this.dbService.getByID("ngsw_version", 1).subscribe({
        next: (result) => {
          resolve(result ? (result as any).version : undefined);
        },
        error: (error) => {
          console.error("Error fetching stored version:", error);
          reject(error);
        },
      });
    });
  }

  // Other Stuff

  public async clearTable(tableName: string) {
    try {
      await this.dbService.clear(tableName).toPromise();
      console.log(`Table "${tableName}" cleared successfully.`);
    } catch (error) {
      if (error.name === "NotFoundError") {
        console.warn(`Table "${tableName}" does not exist, skipping.`);
      } else {
        console.error(`Error clearing "${tableName}" table:`, error);
      }
    }
  }

  public compareSettings(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) return false;

    const compareObjects = (obj1: any, obj2: any): boolean => {
      if (
        typeof obj1 !== "object" ||
        obj1 === null ||
        typeof obj2 !== "object" ||
        obj2 === null
      ) {
        return obj1 == obj2;
      }

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;

      for (const key of keys1) {
        const value1 = obj1[key];
        const value2 = obj2[key];
        if (!compareObjects(value1, value2)) {
          return false; //
        }
      }
      return true;
    };

    const sortedArr1 = [...arr1].sort((a, b) =>
      JSON.stringify(a).localeCompare(JSON.stringify(b))
    );
    const sortedArr2 = [...arr2].sort((a, b) =>
      JSON.stringify(a).localeCompare(JSON.stringify(b))
    );

    for (let i = 0; i < sortedArr1.length; i++) {
      if (!compareObjects(sortedArr1[i], sortedArr2[i])) {
        return false;
      }
    }

    return true;
  }

  public compareObjects(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  public async deleteUserEntry(tableName, user_id) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find((entry) => entry.id === user_id);
   
    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
    }
  }

  public async GetDate() {
    const currentDate = new Date();
    const utcPlus8Date = new Date(currentDate.getTime() + 8 * 60 * 60 * 1000);
    return utcPlus8Date.toISOString().split("T")[0];
  }

  public getCurrentDateSQLFormat() {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  public getFromLocalStorage(key: string): any {
    return JSON.parse(localStorage.getItem(key) || "{}");
  }

  //nested arrays
  public compareSoReasons(obj1: any, obj2: any): boolean {
    const compareObjects = (innerObj1: any, innerObj2: any): boolean => {
      if (
        typeof innerObj1 !== "object" ||
        innerObj1 === null ||
        typeof innerObj2 !== "object" ||
        innerObj2 === null
      ) {
        return innerObj1 == innerObj2;
      }

      const keys1 = Object.keys(innerObj1);
      const keys2 = Object.keys(innerObj2);

      if (keys1.length !== keys2.length) return false;

      for (const key of keys1) {
        const value1 = innerObj1[key];
        const value2 = innerObj2[key];
        if (!compareObjects(value1, value2)) {
          return false;
        }
      }
      return true;
    };

    const sortedKeys1 = Object.keys(obj1).sort();
    const sortedKeys2 = Object.keys(obj2).sort();

    if (sortedKeys1.length !== sortedKeys2.length) return false;

    for (let i = 0; i < sortedKeys1.length; i++) {
      if (!compareObjects(obj1[sortedKeys1[i]], obj2[sortedKeys2[i]])) {
        return false;
      }
    }

    return true;
  }

  public async deleteUserEntryTest(tableName, user_id) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find((entry) => entry.user_id === user_id);
    if (existingEntry) {
      await this.dbService.delete(tableName, existingEntry.id).toPromise();
    }
  }

  public async deleteUserEntryTestCustom(tableName, user_id, ref_number) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find((entry) => entry.user_id === user_id && entry.so_reference === ref_number);
    console.log("existing delete ", existingEntry);
    if (existingEntry) {
      await this.dbService.delete(tableName, existingEntry.id).toPromise();
    }
  }

  public async deleteUserEntryTestCustomDynamic(
    tableName: string,
    user_id: any,
    dynamicProperties: any = {}
  ) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
  
    const searchCriteria = { user_id, ...dynamicProperties };
  
    const existingEntry = entries.find((entry) =>
      Object.keys(searchCriteria).every((key) => {
        const entryValue = entry[key];
        const searchValue = searchCriteria[key];
  
        if (Array.isArray(entryValue)) {
          return entryValue.includes(searchValue);
        }
  
        return entryValue === searchValue;
      })
    );
  
    if (existingEntry) {
      await this.dbService.delete(tableName, existingEntry.id).toPromise();
    }
  }
  

  public async processAEStoIDBwithCustomer1File(
    tableName,
    file: File,
    user_id,
    column_name,
    customer_id,
    dynamicProperties: any = {}
  ) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
  
    const searchCriteria = { user_id, customer_id, ...dynamicProperties };
  
    const existingEntry = entries.find((entry) =>
      Object.keys(searchCriteria).every(
        (key) => entry[key] === searchCriteria[key]
      )
    );
  
    if (existingEntry) {
      await this.dbService.delete(tableName, existingEntry.id).toPromise();
    }
  
    if (!file) {
      console.error("No file provided");
      return [];
    }

    const fileReader = new FileReader();
    const fileData: Promise<ArrayBuffer> = new Promise((resolve, reject) => {
      fileReader.onload = () => resolve(fileReader.result as ArrayBuffer);
      fileReader.onerror = () => reject(fileReader.error);
      fileReader.readAsArrayBuffer(file);
    });
  
    let encryptedFileData: string;
    try {
      const fileContent = await fileData;
  
      const base64String = btoa(
        String.fromCharCode(...new Uint8Array(fileContent))
      );
  
      encryptedFileData = await this.cryptoService.encryptDataBased(base64String);
    } catch (error) {
      console.error("Error reading or encrypting file:", error);
      return [];
    }
  
    const dataPass = {
      user_id,
      customer_id,
      ...dynamicProperties,
      [column_name]: encryptedFileData,
      file_name: file.name,
      file_type: file.type,
      file_size: file.size,
    };
  
    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
      console.log(`File successfully saved with key: ${key}`);
    } catch (error) {
      console.error(`Error adding file to ${tableName}:`, error);
    }
  }
  
  public async getDecryptedAesEntriesWithCustomerSingleFile(
    tableName,
    user_id,
    customer_id,
    column_name,
    dynamicProperties: any = {}
  ) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
  
    const searchCriteria = { user_id, customer_id, ...dynamicProperties };
  
    const userEntry = entries.find((entry) =>
      Object.keys(searchCriteria).every(
        (key) => entry[key] === searchCriteria[key]
      )
    );
  
    if (
      !userEntry ||
      !userEntry[column_name] ||
      userEntry[column_name].length === 0
    ) {
      console.error("No matching entry found or file data is missing");
      return null;
    }
  
    try {
      const decryptedBase64 = await this.cryptoService.decryptDataBased(
        userEntry[column_name]
      );
  
      const binaryString = atob(decryptedBase64);
      const len = binaryString.length;
      const arrayBuffer = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arrayBuffer[i] = binaryString.charCodeAt(i);
      }
  
      const fileBlob = new Blob([arrayBuffer], {
        type: userEntry.file_type || "application/octet-stream",
      });
  
      return {
        fileBlob,
        fileName: userEntry.file_name,
        fileType: userEntry.file_type,
        fileSize: userEntry.file_size,
      };
    } catch (error) {
      console.error("Error decrypting or processing file data:", error);
      return null;
    }
  }

  public async getDecryptedAesEntriesWithCustomerMultipleFiles(
    tableName,
    user_id,
    customer_id,
    column_name,
    dynamicProperties: any = {}
  ) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
  
    // const searchCriteria = { user_id, customer_id, ...dynamicProperties };
    const searchCriteria =
      customer_id === 0
        ? { user_id, ...dynamicProperties }
        : { user_id, customer_id, ...dynamicProperties };
        
    // Find all entries matching the criteria
    const matchingEntries = entries.filter((entry) =>
      Object.keys(searchCriteria).every(
        (key) => entry[key] === searchCriteria[key]
      )
    );
  
    if (!matchingEntries || matchingEntries.length === 0) {
      console.error("No matching entries found");
      return [];
    }
  
    const decryptedFiles = [];
  
    for (const userEntry of matchingEntries) {
      try {
        if (!userEntry[column_name] || userEntry[column_name].length === 0) {
          console.warn(`Entry with ID ${userEntry.id} has no file data`);
          continue;
        }
  
        // Decrypt the Base64 encoded file data
        const decryptedBase64 = await this.cryptoService.decryptDataBased(
          userEntry[column_name]
        );
  
        // Convert Base64 back to an ArrayBuffer
        const binaryString = atob(decryptedBase64);
        const len = binaryString.length;
        const arrayBuffer = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          arrayBuffer[i] = binaryString.charCodeAt(i);
        }
  
        // Create a Blob from the ArrayBuffer
        const fileBlob = new Blob([arrayBuffer], {
          type: userEntry.file_type || "application/octet-stream",
        });
  
        // Add the file blob and metadata to the result
        decryptedFiles.push({
          fileBlob,
          fileName: userEntry.file_name,
          fileType: userEntry.file_type,
          fileSize: userEntry.file_size,
        });
      } catch (error) {
        console.error(
          `Error decrypting or processing file data for entry ID ${userEntry.id}:`,
          error
        );
      }
    }
  
    return decryptedFiles;
  }

  // HTML FILES
  public async saveToIndexedDB(zipId: string, files: { [key: string]: string | ArrayBuffer }) {
    Object.entries(files).forEach(([path, content]) => {
      this.dbService.add('htmlFiles', { zipId, path, content }).subscribe({
        next: () => console.log(`File ${path} saved successfully`),
        error: (err) => console.error(`Error saving file ${path}:`, err),
      });
    });
  }

  public async fetchFilesFromIndexedDB(zipId: string): Promise<{ [key: string]: string }> {
    const files: { [key: string]: string } = {};
    const allFiles = await this.dbService.getAllByIndex('htmlFiles', 'zipId', IDBKeyRange.only(zipId)).toPromise();

    allFiles.forEach((file: any) => {
      files[file.path] = file.content;
    });

    return files;
  }

  public emptyArrayToString(theArray: any) {
    if (Array.isArray(theArray)) {
      if (theArray.length == 0) {
        return ''
      }
    } else {
      return theArray
    }
  }

}


