import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "src/environments/environment";

function decodeKey(key: string) {
  const binaryString = atob(key)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  let index = 0
  while (index < len) {
    bytes[index] = binaryString.charCodeAt(index)
    ++index
  }
  return bytes.buffer
}

const encoder = new TextEncoder()
const decoder = new TextDecoder("utf-8")

export const getRSA =
  (type: "pkcs8" | 'raw' | 'spki', key: string, tasks: KeyUsage[]) =>
    window.crypto.subtle.importKey(type, decodeKey(key), { name: "RSA-OAEP", hash: "SHA-256" }, true, tasks);
const publicKey = getRSA("spki", environment.key, ["encrypt"])
const privateKey = getRSA("pkcs8", environment.pvt, ["decrypt"])

export const decryptPermissions = (buf: BufferSource) =>
  privateKey.then(key => window.crypto.subtle.decrypt({ name: "RSA-OAEP" }, key, buf))
    .then(buf => decoder.decode(buf))
    .catch(err => {return "" })

export const decrypt = (buf: BufferSource) =>
  privateKey.then(key => window.crypto.subtle.decrypt({ name: "RSA-OAEP" }, key, buf))
    .then(buf => decoder.decode(buf))
    .catch(err => { console.log(err + `\n ${buf} is bad data.`); return "" })

export const encrypt = (val: string) => publicKey.then(key => window.crypto.subtle.encrypt({ name: "RSA-OAEP" },
  key,
  encoder.encode(val)))
@Pipe({
  standalone: true,
  name: 'decrypt'
})
export class DecryptorPipe implements PipeTransform {
  transform = (value: BufferSource) => decrypt(value!)
}
@Pipe({
  standalone: true,
  name: 'encrypt'
})
export class EncryptorPipe implements PipeTransform {
  transform = (value: string) => encrypt(value!)
}



