import { Component, HostBinding, OnInit, ApplicationRef} from '@angular/core';
import { LanguageService } from './services';
import { Language } from 'aws-sdk/clients/support';
import { OverlayContainer } from '@angular/cdk/overlay';
import { environment } from '../environments/environment';
import { SwUpdate, SwRegistrationOptions } from '@angular/service-worker';
import { AcpIndexDBService } from './services/indexed-db/acp-index-db.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { first } from 'rxjs/operators';

@Component({
  selector: 'esc-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  public moduleName;
  public isDark: boolean = false;

  @HostBinding('class')
  get themeMode(){
    return this.isDark ? 'theme-dark' : 'theme-light'
  }

  async ngOnInit() {
    await this.checkAndUpdateVersion();
  }

  constructor(language : LanguageService, private overlayContainer: OverlayContainer, private dbService: AcpIndexDBService,private swUpdate: SwUpdate, private appRef: ApplicationRef) {
    if(localStorage.getItem('darkMode') == 'true'){
      this.isDark = true;
    } else if(localStorage.getItem('darkMode') == 'false'){
      this.isDark = false;
    } else {
      this.isDark = false;
    }
    this.themeSwitch(this.isDark);
    language.initLocale();
    this.overlayTheme();
  }

  themeSwitch(data?: any){
    this.isDark = data;
    this.overlayTheme();
    if(this.isDark){
      localStorage.setItem('darkMode', 'true');
    } else {
      localStorage.setItem('darkMode', 'false');
    }   
  }

  private async updateServiceWorker() {
    if (navigator.serviceWorker) {
      const registration = await navigator.serviceWorker.getRegistration();
  
      if (registration) {
        // Unregister the existing service worker
        await registration.unregister();
        console.log('Service worker unregistered.');
  
        // Re-register the service worker
        if(environment.production){
          await navigator.serviceWorker.register('/ngsw-worker.js');
        }

        console.log('Service worker re-registered due to version change.');
      }
    }
  }

  private async checkAndUpdateVersion() {
    const storedVersion = await this.dbService.getStoredVersion();
    const newVersion = environment.ngswVersion;
    if (storedVersion !== newVersion || !storedVersion ) {
      await this.unregisterAndReregisterServiceWorker();
      await this.storeVersion(newVersion);
    }
  }

  
  private async unregisterAndReregisterServiceWorker() {
    if (navigator.serviceWorker) {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration) {
        await registration.unregister();
        console.log('Service worker unregistered.');

        if (!environment.production) {
          console.log()
          await navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then((reg) => {
              console.log('Firebase messaging service worker registered:', reg);
            })
            .catch((error) => {
              console.error('Firebase messaging service worker registration failed:', error);
            });
        }

        if(environment.production){
          await navigator.serviceWorker.register('/ngsw-worker.js');
        }
        console.log('Service worker registration method called. It will register when the app is stable.');
      } 
    } 
  }



  private async storeVersion(version: string) {
    const existingVersion = await this.dbService.getStoredVersion();
    if (existingVersion) {
      await this.dbService.updateVersion('ngsw_version', version);
    } else {
      await this.dbService.storeVersion('ngsw_version', version);
    }
  }

  
  private overlayTheme() {
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const classesToRemove = Array.from(overlayContainerClasses).filter(item => item.includes('theme-'));
    overlayContainerClasses.remove(...classesToRemove); 
    this.overlayContainer.getContainerElement().classList.add(this.isDark ? 'theme-dark' : 'theme-light');
  }
}
