export const LANGUAGES = [
  { 
    value: 'en',
    name: 'English'
  },
  { 
    value: 'id',
    name: 'Indonesia'
  },
  { 
    value: 'fil',
    name: 'Filipino'
  }
];

export const DEFAULT_LANGUAGE = 'en';
export const KEY_STORAGE_LANGUAGE = 'language';