import { DBConfig } from 'ngx-indexed-db';

export const dbConfig: DBConfig = {
    name: 'revampDB',
    version: 41,
    objectStoresMeta: [{
        store: 'acp_settings',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'settings', keypath: 'settings', options: { unique: false } },
        ]
    },
    {
        store: 'acp_call_modes',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'call_modes', keypath: 'call_modes', options: { unique: false } },
        ]
    },    
    {
        store: 'acp_cycle_dates',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_dates', keypath: 'cycle_dates', options: { unique: false } },
        ]
    },
    {
        store: 'acp_holder',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'calls', keypath: 'calls', options: { unique: false } },
        ]
    },
    {
        store: 'acp_activities_holder',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'activity_calls', keypath: 'activity_calls', options: { unique: false } },
        ]
    },
    {
        store: 'acp_doctor_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'doctors', keypath: 'doctors', options: { unique: false } },
        ]
    },
    {
        store: 'acp_activities_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'activities', keypath: 'activities', options: { unique: false } },
        ]
    },
    {
        store: 'app_user',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'code', keypath: 'code', options: { unique: false } },
            { name: 'email_address', keypath: 'email_address', options: { unique: false } },
            { name: 'first_name', keypath: 'first_name', options: { unique: false } },
            { name: 'last_name', keypath: 'last_name', options: { unique: false } },
            { name: 'middle_name', keypath: 'middle_name', options: { unique: false } },
            { name: 'username', keypath: 'username', options: { unique: false } },
        ]
    },
    {
        store: 'web_permissions',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'permissions', keypath: 'permissions', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_incidental_list', keypath: 'acp_incidental_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_activity', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_incidental_activity_list', keypath: 'acp_incidental_activity_list', options: { unique: false } },
        ]

    },
    {
        store: 'acp_dm_list', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'dm_list', keypath: 'dm_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_product_list', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'products_lists', keypath: 'products_lists', options: { unique: false } },
        ]
    },
    {
        store:'acp_products', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'doctor_id', keypath: 'doctor_id', options: { unique: false } },
            { name: 'products', keypath: 'products', options: { unique: false } },
        ]
    },
    {
        store:'acp_products_planned', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'products', keypath: 'products', options: { unique: false } },
        ]
    },
    {
        store: 'acp_note_list', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'note_list', keypath: 'note_list', options: { unique: false } },
        ]
    },
    {
        store:'acp_notes', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'doctor_id', keypath: 'doctor_id', options: { unique: false } },
            { name: 'notes', keypath: 'notes', options: { unique: false } },
        ]
    },
    {
        store:'acp_notes_planned', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'notes', keypath: 'notes', options: { unique: false } },
        ]
    },
    {
        store:'acp_notes_activity', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'notes', keypath: 'notes', options: { unique: false } },
        ]
    },
    {
        store:'acp_notes_activity_planned', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'notes', keypath: 'notes', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_doctor_start', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'incidental_doctor_start_list', keypath: 'incidental_doctor_start_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_doctor_start_planned', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'incidental_doctor_start_planned', keypath: 'incidental_doctor_start_planned', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_activity_start', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'incidental_activity_start_list', keypath: 'incidental_activity_start_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_activity_start_planned', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'incidental_activity_start_planned_list', keypath: 'incidental_activity_start_planned_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_material', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_material_list', keypath: 'acp_material_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_offline_send', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_offline_send_list', keypath: 'acp_offline_send_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_activity_offline_send', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_activity_offline_send_list', keypath: 'acp_activity_offline_send_list', options: { unique: false } },
        ]
    },
    {
        store:'acp_activity_details', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'details', keypath: 'details', options: { unique: false } },
        ]
    },
    {
        store: 'ngsw_version',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: [
          { name: 'version', keypath: 'version', options: { unique: false } },
        ]
      },
      {
        store: 'dashboard_rate_past', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'rate_past', keypath: 'rate_past', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_reach_past', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'reach_past', keypath: 'reach_past', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_concentration_past', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'concentration_past', keypath: 'concentration_past', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_cycle', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle', keypath: 'cycle', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_summary_headers', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'summary_headers', keypath: 'summary_headers', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_rate', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'rate', keypath: 'rate', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_reach', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'reach', keypath: 'reach', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_concentration', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'concentration', keypath: 'concentration', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_active', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_active', keypath: 'time_in_active', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_date', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_date', keypath: 'time_in_date', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_image', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_image', keypath: 'time_in_image', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_remarks', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_remarks', keypath: 'time_in_remarks', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_signature', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_signature', keypath: 'time_in_signature', options: { unique: false } },
        ]
    },
      

    ]
}