export class Path {
  public static readonly LOGIN = {
    GET_USER_DETAILS: "web-login/sync",
    GET_USERPOOL: "license/get-user-pool",
    GET_USERPOOL2: "license-2/get-user-pool",
    UPSERT_TRANSACTION: "user-transaction-web/upsert_last_login",
    GET_LANDING_PAGE_STATUS: "user-transaction-web/get_landing_status",
    UPDATE_LANDING_PAGE_STATUS: "user-transaction-web/update_landing_status",
    GET_APP_USER: "user-transaction-web/get_user",
    UPSERT_PASSWORD_EXPIRY: "user-transaction-web/upsert-password-expiry",
    GET_PASSWORD_EXPIRY: "user-transaction-web/get-password-expiry",
  };

  public static readonly ACCOUNT_SETTINGS = {
    GET_LIST: "host-app-setting/sync",
    SAVE_SETTINGS: "host-app-setting/sync"
  };

  public static readonly ACCOUNT = {
    GET_LIST: "principals"
  }

  public static readonly CHANNEL = {
    GET_LIST: "channels"
  }

  public static readonly REGION = {
    GET_LIST: "regions"
  }

  public static readonly ROLE = {
    GET_LIST: "roles",
    ADD: "",
    UPDATE: ""
  }

  public static readonly STORE = {
    GET_LIST: "stores",
    GET_DETAIL: "stores",
    ADD: "stores",
    UPDATE: "stores",
    EXPORT: "stores/export"
  }

  public static readonly TS = {
    GET_LIST: "trade-supervisors"
  }

  public static readonly USER = {
    GET_LIST: "users",
    GET_DETAIL: "users",
    ADD: "users",
    UPDATE: "users",
    RESET_PASSWORD: "users/reset-password",
    EXPORT: "users/export",
    LIST: "user/list"
  }

  public static readonly ATTENDANCE_REPORT = {
    GET_REPORT_DATA: "attendance-report/attendance_report",
    EXPORT: "attendance-report/task-runner",
    LIST_DISTRICT: "attendance-report/list_district",
    LIST_TERRITORY: "attendance-report/list_territory",
    LIST_USER: "attendance-report/list_user"
  }
  public static readonly RED_FLAG_REPORT = {
    GET_REPORT_DATA: "report/red-flag",
    EXPORT: "report/red-flag/export"
  }

  public static readonly RAW_DATA_REPORT = {
    GET_COUNT: "raw-data",
    EXPORT: "raw-data/export"
  }

  public static readonly CUSTOMER = {
    GET_LIST: "users",
    GET_DETAIL: "users",
    ADD: "users",
    UPDATE: "users",
    RESET_PASSWORD: "users/reset-password",
    EXPORT: "users/export"
  }

  public static readonly KPI = {
    GET_LIST: "users",
    GET_DETAIL: "users",
    GET_RATE: "dashboard-host/rate",
    GET_REACH: "dashboard-host/reach",
    GET_CONCENTRATION: "dashboard-host/concentration",
    GET_CYCLE_LIST: "dashboard-host/cycle-list",
    GET_AREA_LIST: "dashboard-host/area-list",
    GET_DISTRICT_LIST: "dashboard-host/district-list",
    GET_CLASS_LIST: "dashboard-host/class-list",
    GET_SPECIALIZATION_LIST: "dashboard-host/specialization-list",
    GET_RATE_PAST_CYCLES: "dashboard-host/rate-past-cycles",
    GET_REACH_PAST_CYCLES: "dashboard-host/reach-past-cycles",
    GET_CONCENTRATION_PAST_CYCLES: "dashboard-host/concentration-past-cycles",
    GET_SUMMARY_DETAILS: "dashboard-host/call-summary-details",
    GET_SUMMARY_HEADERS: "dashboard-host/call-summary-headers",
    GET_RATE_DETAILS: "dashboard-host/call-rate-details",
    GET_REACH_DETAILS: "dashboard-host/call-reach-details",
    GET_CONCENTRATION_DETAILS: "dashboard-host/call-concentration-details",
    GENERATE_REPORT: "dashboard-host/test_email",
    GET_CYCLE_DATES: "dashboard-host/get_dates",
    GET_ACTIVITIES_DASHBOARD: "dashboard-host/get_activities",
    GET_DEVELOPMENTAL_PLAN: "dashboard-host/get_developmental_plan"

  }

  public static readonly DETAILING_AID = {
    LIST_DETAILING_AID: "detailing-aid-web/list_web_detailing_aid",
    GET_DETAILING_AID: "detailing-aid-web/get_web_detailing_aid",
    POST_EMAIL_DETAILING_AID: "detailing-aid-web/email_web_detailing_aid",
    LIST_PRODUCT_DETAIL_AID: "detailing-aid-web/get_products_detailing_aid",
    UPLOAD_DETAILING_AID: "detailing-aid-web/insert_web_detailing_aid",

    LIST_MAP_DETAILING_AID_BY_DA_ID: "detailing-aid-web/list_web_products_detailing_aid",
    UPLOAD_PRODUCT_DETAIL_MAP: "detailing-aid-web/insert_web_product_mapping",

    LIST_PRODUCT: "products-web/list_app_products",

    LIST_SPECIALIZATION: "web-specialization/list_specialization",
    LIST_SPECIALIZATION_MPDA: "web-specialization/list_specialization_mpda",
    LIST_DA_BY_SPECIALIZATION_MAP: "detail-specialization-web/list_map_da_specialization_id",
    UPLOAD_SPECIALIZATION_DETAIL_MAP: "detail-specialization-web/insert_map_detail_aid_specialization",
    GET_MDAS: "detail-specialization-web/get_map_detail_aid_specialization",

    LIST_WEB_DA_BY_SPECIALIZATION: "detailing-aid-web/list_web_da_by_specialization",
    LIST_WEB_DA_NOT_BY_SPECIALIZATION: "detailing-aid-web/list_web_da_by_not_specialization",
  }

  public static readonly DOCTORS = {
    GET_DOCTORS: "app-doctors-list-web/get-doctors-web",
    GET_DOCTOR_INSTITUTION: "app-institutions-list-web/get-institutions-web",
    LIST_DOCTORS_FILTER: "doctor/list-doctors-filter",
    LIST_DOCTOR_INFO:"app-doctors-list-web/list_doctor_info",
    LIST_DOCTORS: "doctor-maintenance/list-doctors",
    GET_DOCTOR_DETAIL: "doctor-maintenance/get-doctor-detail",
    GET_DOCTOR_INFO: "doctor-maintenance/get-doctor-info",
    LIST_PRODUCTS: "doctor-maintenance/get-doctor-product-map",
    GET_CALL_TIME: "doctor-maintenance/get-call-time",
    ADD_DOCTOR: "doctor-maintenance/add-doctor-institution",
    LIST_DYNAMIC_OPTIONS: "doctor-maintenance/list-dynamic-info",
    LIST_PRODUCT_OPTIONS: "doctor-maintenance/list-products",
    ADD_DOCTOR_INFO: "doctor-maintenance/add-dynamic-info",
    ADD_PRODUCTS: "doctor-maintenance/add-products",
    ADD_CALL_TIME: "doctor-maintenance/add-call-time",
    GET_DOCTOR_PRIMARY_DETAILS: "doctor-maintenance/get-doctor-primary-details",
    UPDATE_DOCTOR: "doctor-maintenance/update-doctor",
    GET_DOCTOR_MAPPINGS: "doctor-maintenance/list-doctor-institution",
    UPDATE_MAPPINGS: "doctor-maintenance/update-doctor-institution",
  }


  public static readonly LANDING_PAGE = {
    LIST_IMAGES: "web-landing-page/list_landing_page",
    GET_DOCTOR_INSTITUTION: "app-institutions-list-web/get-institutions-web",
  }

  public static readonly ACTIVITIES = {
    GET_ACTIVITIES: "sfe-web-get-activity/get_activity_web",
    LIST_ACTIVITIES: "activity/list"
  }

  public static readonly ATTENDANCE = {
    ADD_ATTENDANCE: "attendance-web/insert_attendance",
    LIST_ATTENDANCE_PAIR: "attendance-web/list_attendance_by_batch",
    LIST_ATTENDANCE_INSTITUTION: "institutions-web/list_web_institution",

    GET_LATE_TIME: "user-transaction-web/get_late_time",
    CHECK_ATTENDANCE_PER_DAY: "attendance-web/list_attendance_in_day",

    GET_CYCLE_DATES: "user-transaction-web/get_dates",
    LIST_ATTENDANCE_DETAIL_COUNT: "attendance-web/list_attendance_per_cycle",

    GET_ATTENDANCE_SOLO: "attendance-web/get_attendance_solo",

    GET_SERVER_TIME: "attendance-web/get_server_time"
  }

  public static readonly INSTITUTION = {
    LIST_INSTITUTION: "web-host-institutions/list_web_institution",
    GET_INSTITUTION: "institutions-web/get_web_institution",
    TRY_INSTITUTION: "institutions-device/list_app_institutions",
    LIST_INSTITUTIONS: "institution-maintenance/list-institutions",
    GET_INSTITUTION_DETAIL: "institution-maintenance/get-institution-detail",
    LIST_TEMP_INSTITUTIONS: "institution-maintenance/list-temp-institutions",
    GET_TEMP_INSTITUTION_DETAIL: "institution-maintenance/get-temp-institution-detail",
    UPSERT_INSTITUTION: "institution-maintenance/upsert-institution",
    DOWNLOAD_INSTITUTIONS: "institution-maintenance/task-runner",
    APPROVE_INSTITUTION: "institution-maintenance/approve-institution",
    REJECT_INSTITUTION: "institution-maintenance/reject-institution",
    SEARCH_INSTITUTION: "institution-maintenance/search-institution",
  }

  public static readonly ACP = {
    GET_PLOTTED: "acp/get",
    GET_SETTINGS: "acp/acp-settings",
    GET_FILTER: "acp/filter",
    GET_DOC_INFO: "acp/info",
    GET_PRODUCTS: "acp/products", 
    GET_PRODUCTS_ALL: "acp/get_products_all",
    GET_REASONS: "acp/reasons",
    GET_CALL_REASONS: "acp/call_reasons",
    GET_DM: "acp/dm",
    GET_INCIDENTAL_LIST: "acp/incidental",
    GET_CALL_MODES: "acp/call-mode",
    RESCHED_MCP: "acp/resched_mcp",
    GET_TXN_INFO: "acp/get_txn_info",
    POST_ACP: "acp/post_acp",
    GET_MISSED_CALLS: "acp/get_missed_calls",
    GET_CALL_INFO: "acp/get_call_info",
    DECLARE_MISSED: "acp/declare_miss",
    GET_ACTIVITIES: "acp/get_activities",
    SEND_ACP: "acp/send_acp",
    GET_POST_CALL_TYPES: "acp/get_post_call_types",
    SAVE_TEMP: "acp/save_temp",
    GET_APP_MATERIALS: "acp/get_app_materials",
    GET_TEMP_DETAILS: "acp/get_temp_details",
    POST_ACTIVITY: "acp/post_activity",
    GET_CALL_INFO_ACTIVITY: "acp/get_call_info_activity",
    SEND_ACTIVITY: "acp/send_activity",
    GET_ACTIVITES_LIST_INCIDENTAL: "acp/get_activities_list",
    SAVE_TEMP_POST: "acp/save_temp_post_call",
    GET_TEMP_POST_DETAILS: "acp/get_temp_post_details",
    SAVE_TEMP_ACTIVITY: "acp/save_temp_activities",
    GET_TEMP_DETAILS_ACTIVITY: "acp/get_temp_details_activity",
    SAVE_TEMP_POST_ACTIVITY: "acp/save_temp_post_call_activity",
    GET_TEMP_POST_DETAILS_ACTIVITY: "acp/get_temp_post_details_activity",
    RESCHED_ACTIVITY: "acp/resched_activity",
    SAVE_DM_SIGN: "acp/save_dm_sign_doctor",
    SEND_ALL_ACP: "acp/send_all_acp"
  }

  public static readonly DM_ACP = {
    GET_USER_DETAILS: "acp-dm/info",
    GET_DM_PRODUCTS: "acp-dm/dm_products",
    GET_MATERIALS: "acp-dm/materials",
    GET_EVAL: "acp-dm/eval",
    GET_DM_DOCTORS: "acp-dm/dm_doctors",
    POST_DM_ACP: "acp-dm/post_dm_acp",
    GET_TXN_DETAILS: "acp-dm/txn_details",
    GET_PMR_LIST: "acp-dm/pmr_list",
    GET_JOINT_CALL_PLOTTED: "acp-dm/joint_call",
    POST_DM_ACTIVITY: "acp-dm/post_dm_activity",
    GET_SERVICE_CALL_PLOTTED: "acp-dm/service_call",
    GET_DM_CALL_INFO: "acp-dm/call_info"
  }

  public static readonly MCP = {
    GET_MCP_SETTINGS:"app-host-get-web-mcp-settings/list-mcp-settings",

    GET_TEMP_MCP: "sfe-host-mcp-post/list_temp_mcp",
    INSERT_TEMP_HEADER: "sfe-host-mcp-post/insert_temp_mcp_header",
    INSERT_TEMP_COMPONENT: "sfe-host-mcp-post/insert_temp_mcp",
    UPDATE_COMPONENT:"sfe-host-mcp-post/insert_temp_mcp_with_header",
    SUBMIT_COMPONENT:"sfe-host-mcp-post/insert_mcp",
    SUBMIT_HEADER:"sfe-host-mcp-post/insert_mcp_header",
    GET_MCP: "sfe-host-mcp-post/list_mcp",

    GET_TEMP_ACTIVITY_MCP: "sfe-host-mcp-post-activity/list_temp_mcp_activity", //used
    INSERT_TEMP_ACTIVITY: "sfe-host-mcp-post-activity/insert_temp_mcp_activity", //used
    INSERT_TEMP_ACTIVITY_WITH_HEADER: "sfe-host-mcp-post-activity/insert_temp_mcp_activity_with_header", //used
    SUBMIT_ACTIVITY:"sfe-host-mcp-post-activity/insert_mcp_activity", //used
    GET_ACTIVITY: "sfe-host-mcp-post-activity/list_mcp_activity",

    DELETE_MCP: "sfe-host-mcp-post/delete_mcp",
    GET_DM_MCP: "mcp-dm/list_service_call",
    GET_PMR: "mcp-dm/list_pmr"
  }

  public static readonly DM_MCP = {
    INSERT_TEMP_HEADER: "mcp-dm/insert_temp_cycle_plan_header",
    INSERT_TEMP_CYCLE_PLAN : "mcp-dm/insert_temp_cycle_plan",
    INSERT_TEMP_JOINT_CALL: "mcp-dm/insert_temp_joint_call",
    INSERT_TEMP_SERVICE_CALL: "mcp-dm/insert_temp_service_call",
    INSERT_HEADER: "mcp-dm/insert_cycle_plan_header",
    INSERT_CYCLE_PLAN:"mcp-dm/insert_cycle_plan",
    INSERT_JOINT_CALL: "mcp-dm/insert_joint_call",
    INSERT_SERVICE_CALL: "mcp-dm/insert_service_call",
    DELETE_MCP_HEADER: "mcp-dm/delete_dm_mcp_header",
    DELETE_MCP_CYCLE_PLAN: "mcp-dm/delete_dm_mcp_cycle_plan",
    DELETE_MCP_SERVICE_CALL: "mcp-dm/delete_dm_mcp_service_call",
    DELETE_MCP_JOINT_CALL: "mcp-dm/delete_dm_mcp_joint_call",
    DELETE_DM_MCP: "mcp-dm/delete_dm_mcp",
    GET_TEMP_PMR: "mcp-dm/list_temp_pmr_mcp",
    GET_TEMP_SERVICE: "mcp-dm/list_temp_service_call_mcp",
    GET_SERVICE_CALL: "mcp-dm/list_service_call_mcp",
    GET_PMR_CALL: "mcp-dm/list_pmr_mcp",
    GET_PMR_INFO: "mcp-dm/get_pmr_info"
  }

  public static readonly SO = {
    GET_INSTITUTION_DETAILS: "so/institution_details",
    GET_PAYMENT_TERMS: "so/payment_terms",
    GET_SO_PRODUCTS: "so/get_products",
    GET_BLOCKING_CONDITIONS: "so/blocking_condition",
    GET_SO_HEADERS: "so/so_headers",
    SO_UPSERT: "so/so_upsert",
    GET_SO_DETAILS: "so/so_details",
    LIST_INSTITUTIONS: "so/institutions",
    DELETE_SO: "so/delete_so",
    LIST_REASONS: "so/get_reasons"
  }

  public static readonly DEFAULT_SETTINGS = {
    GET_DEFAULT_SETTING: "web-default-setting/get",
    LIST_DEFAULT_SETTINGS: "web-default-setting/list",
    INSERT_DEFAULT_SETTING: "web-default-setting/insert",
    UPDATE_DEFAULT_SETTING: "web-default-setting/update",
    DELETE_DEFAULT_SETTING: "web-default-setting/delete",
    GET_BY_NAME: "web-default-setting/get_by_name",
    EXPORT_DEFAULT_SETTING: "web-default-setting/export",
  }
  
  public static readonly DOCTOR_SIGNATURE_REPORT = {
    list: "doctor-signature-report/list",
    download: "doctor-signature-report/task-runner"
  }

  public static readonly ACTIVITY_REPORT = {
    list: "activity-report/list",
    download: "activity-report/task-runner"
  }
  
  public static readonly DISTRICT = {
    list: "district/list"
  }

  public static readonly TERRITORY = {
    LIST_TERRITORY_BY_ID: 'territory/list-territory'
  }
  
  public static readonly USER_ROLE = {
    get: "user-role/get"
  }

  public static readonly REASON = {
    list: "reason/list"
  }

  public static readonly DIVISION = {
    list: "division/list"
  }
  
  public static readonly CYCLE_NUMBER = {
    GET_CYCLE_DATES:"app-doctors-list-web/get_cycle_dates",
    GET_CYCLE_NUMBER:"app-doctors-list-web/get_cycle_number"
  }

  public static readonly CYCLE_CALENDAR = {
    LIST_CYCLE_HEADERS: "cycle-calendar/list-cycle-headers",
    LIST_CYCLE_NUMBERS: "cycle-calendar/list-cycle-numbers"
  }

  public static readonly USER_PERMISSIONS = {
    GET_USER_PERMISSIONS:"app-user-permissions/get-permissions",
    GET_WEB_MATRIX_PERMISSIONS: "security-matrix/summary"
  }
  
  public static readonly LABELS = {
    GET_MCP_LABELS: "web-default-settings-mcp-label/get_mcp_labels"
  }

  public static readonly SECURITY_MATRIX = {
    GET_SECURITY_MATRIX: "security-matrix/get",
    INSERT_SECURITY_MATRIX: "security-matrix/insert",
    LIST_SECURITY_MATRIX_HEADER: "security-matrix/header",
    UPDATE_SECURITY_MATRIX: "security-matrix/update",
    GET_TEMPLATE_MATRIX: "security-matrix/template",
  }

  public static readonly LANDING_PAGE_MAINTENANCE = {
    GET_LANDING_PAGE_IMAGE: 'web-landing-page/get_maintenance',
    LIST_LANDING_PAGE_IMAGE: 'web-landing-page/list_maintenance',
    INSERT_LANDING_PAGE_IMAGE: 'web-landing-page/insert',
    UPDATE_LANDING_PAGE_IMAGE: 'web-landing-page/update'
  }

  public static readonly MCP_APPROVAL = {
    LIST: 'mcp-approval/list',
    GET_MD_COUNT: 'mcp-approval/md-count',
    GET_SPECIALIZATION_COUNT: 'mcp-approval/specialization-count',
    GET_CLASS_COUNT: 'mcp-approval/class-count',
    GET_ACTIVITY_COUNT: 'mcp-approval/activity-count',
    APPROVE: 'mcp-approval/approve-mcp',
    REJECT: 'mcp-approval/reject-mcp',
    DOWNLOAD_DETAILS: 'mcp-approval/task-runner',
    DOWNLOAD_LIST: 'mcp-approval-export/task-runner'
  }

  public static readonly MISSED_CALL_APPROVAL = {
    LIST: 'missed-call-approval/list',
    GET: 'missed-call-approval/get',
    ACCEPT: 'missed-call-approval/accept',
    REJECT: 'missed-call-approval/reject',
    EXPORT: 'missed-call-approval/task-runner'
  }

  public static readonly STATUS_FLAGS = {
    LIST_MCP_STATUSES: "status-flags/list-mcp-statuses",
    LIST_APPROVAL_STATUSES: "status-flags/list-approval-statuses"
  }
  public static readonly PEDA_REPORT = {
    GET_QS: "quicksight-host/get-qs-embed-url",
    
    //SAVE MCP
  }

  public static readonly DOCTOR_APPROVAL = {
    LIST: 'doctor-approval/list',
    LIST_MDI: 'doctor-approval/list-mdi',
    GET: 'doctor-approval/get-details-for-comparison',
    APPROVE: 'doctor-approval/approve-doctor',
    DISAPPROVE: 'doctor-approval/disapprove-doctor',
    DOWNLOAD_LIST: 'doctor-approval/task-runner',
    APPROVE_MDI: 'doctor-approval/approve-mdi',
    DISAPPROVE_MDI: 'doctor-approval/disapprove-mdi'
  }
  
  public static readonly APPROVER_MAINTENANCE = {
    LIST_APPROVER_MAINTENANCE: "approver-maintenance/list",
    GET_APPROVER_MAINTENANCE: "approver-maintenance/get",
    UPDATE_APPROVER_MAINTENANCE: "approver-maintenance/update",
    INSERT_APPROVER_MAINTENANCE: "approver-maintenance/insert",
    EXPORT_APPROVER_MAINTENANCE: "approver-maintenance/task-runner"
  }
  public static readonly FILTER_OPTIONS = {
    LIST_APPROVAL_STATUS: "filter-options/list_approval_status",
    LIST_DISTRICT: "filter-options/list_district",
    LIST_TERRITORY: "filter-options/list_territory",
    LIST_REASON: "filter-options/list_reason",
    LIST_USER: "filter-options/list_user",
    LIST_APPROVAL_MODULE: "filter-options/list_approval_module",
    LIST_INSTITUTION: "filter-options/list_institution",
    LIST_AREA: "filter-options/list_area",
    LIST_CLASS: "filter-options/list_class",
    LIST_SPECIALIZATION: "filter-options/list_specialization",
    GET_DEVPLAN_FILTERS: "filter-options/get_devplan_filters",
    LIST_ACTIVITIES: 'filter-options/list_off_activities',
    GET_CURRENT_CYCLE: 'filter-options/get_current_cycle',
    LIST_SO_FLAG_STATUS: 'filter-options/list_so_flag_status'
  }
  
  public static readonly DEVPLAN = {
    LIST_HEADERS: "devplan/header",
    LIST_DETAIL: "devplan/detail",
    GET_DETAIL: "devplan/detail",
    UPDATE_DETAIL: "devplan/update_detail",
    INSERT_DEVPLAN: "devplan/insert_devplan",
    INSERT_DEVPLAN_DETAIL: "devplan/insert_devplan_detail",
    INSERT_TEMP_DEVPLAN: "devplan/insert_temp_devplan",
    INSERT_TEMP_DEVPLAN_DETAIL: "devplan/insert_temp_devplan_detail",
    EMAIL_DEVPLAN: "devplan/email_devplan",
    LIST_JOINT_CALL_SUMMARIES: "devplan/joint_call",
    LIST_JOINT_CALL_OVERALL_CATEGORY: "devplan/joint_call_category"
  }

  public static readonly SO_APPROVAL = {
    LIST: 'sales-order-approval/list',
    MAX_APPROVER: 'sales-order-approval-details/max_approver',
    DETAILS: 'sales-order-approval-details/details',
    CURRENT_LEVEL: 'sales-order-approval-details/current_user_level',
    APPROVE: 'sales-order-approval/approve',
    DISAPPROVE:'sales-order-approval/disapprove',
    TASK_RUNNER:'sales-order-approval/task-runner',
    GET_STATUS_FLAGS: 'sales-order-approval-details/get_status_flags',
    UPDATE_SO_REQUEST_STATUS: 'sales-order-approval/update'
  }

  public static readonly WEB_TUTORIAL = {
    INSERT: 'web-tutorial/insert',
    GET_MODULES: 'web-tutorial/get_modules',
    ENABLE_DISABLE:'web-tutorial/enable_disable',
    GET_TUTORIALS: 'web-tutorial/get_tutorials',
    GET_ENABLED_TUTORIALS: 'web-tutorial/get_enabled_tutorials',
    DELETE_TUTORIAL:'web-tutorial/delete_tutorial'
  }

  public static readonly USER_MAINTENANCE = {
    GET_LIST: 'user-maintenance/list-all-user',
    LIST_USERS: 'user-maintenance/list-users',
    GET_USER: 'user-maintenance/search-user',
    UPDATE_USER: 'user-maintenance/update-user',
    GET_MANAGERS: 'user-maintenance/list-managers',
    UPDATE_MUDA: 'user-maintenance/update-muda',
    ADD_USER_INFO: 'user-maintenance/add-user-info',
    ADD_USER_TRANSACTION: 'user-maintenance/add-user-transactions',
    RESET_INSTALLATION_ID: 'user-maintenance/reset-installation-id',
    EXPORT_REPORT: 'user-maintenance/task-runner',
    DISABLE_USER: 'cognito-admin-functions/disable-user',
    ADD_NEW_USER: 'cognito-admin-functions/add-new-user',
    ENABLE_USER: 'cognito-admin-functions/enable-user',
    RESET_USER_PASSWORD: 'cognito-admin-functions/reset-user-password',
    UPDATE_USER_STATUS: 'user-maintenance/update-user-status',
  }

  public static readonly DYNAMIC_SELECTIONS = {
    GET_ROLES: 'dynamic-selections/list-all-roles',
    GET_USER_TYPES: 'dynamic-selections/list-all-user-type',
    GET_EVAL_HEADERS: 'dynamic-selections/list-all-eval-headers',
    GET_ASSOCIATED_DISTRICT: 'dynamic-selections/get-associated-district',
    GET_UNOCCUPIED_DISTRICT: 'dynamic-selections/list_unoccupied-district',
    GET_UNOCCUPIED_TERRITORY: 'dynamic-selections/list-unoccupied-territory',
    GET_UNOCCUPIED_AREA: 'dynamic-selections/list-unoccupied-area',
  }

  public static readonly BROADCAST_MESSAGE = {
    INSERT: 'broadcast-message'
  }

  public static readonly WEB_NOTIFICATION = {
    SEND_NOTIFICATION: 'web-notification/send_notification',
    INSERT_TOKEN: 'web-notification/insert_token',
    DELETE_TOKEN: 'web-notification/delete_token',
    GET_NOTIFICATIONS: 'web-notification/get_all_notifications',
    MARK_AS_READ: 'web-notification/mark_notification_read',
  }
  
  public static readonly DATA_MAINTENANCE = {
    POPULATE_TEMPLATE: 'populate-template/task-runner',
    PROCESS_FILE: 'data-maintenance/task-runner'
  }
  
  public static readonly MATERIAL_MONITORING = {
    LIST_PRODUCTS: 'material-monitoring/list_products'
  }
}