<h1 mat-dialog-title>{{ field.caption }}</h1>

<div id="signature-canvas-modal" class="body" fxLayout="column" fxLayoutGap="20px" fxFlexFill>

	<mat-card class="custom-card">
		<mat-progress-bar mode="indeterminate" *ngIf="loading.save" fxLayoutGap="16px"></mat-progress-bar>
	
		<mat-card-content class="card-content">
			<canvas #modalSignature
				(mousedown)="startDrawing($event)"
				(mousemove)="draw($event)"
				(mouseup)="stopDrawing()"
				(touchstart)="startDrawing($event)"
				(touchmove)="draw($event)"
				(touchend)="stopDrawing()"
				class="signature-canvas">
			</canvas>
		</mat-card-content>
	
		<div class="card-footer">
			<div class="action-btns">
				<button mat-icon-button (click)="clearCanvas()" [disabled]="canvasEmpty" matTooltip="Delete">
					<mat-icon [style.color]="canvasEmpty ? 'rgba(0, 0, 0, 0.38)' : (validated ? 'inherit' : 'red')">delete</mat-icon>
				</button>
				<button color="primary" mat-icon-button (click)="handleSignClick()" matTooltip="Save" [disabled]="validated || canvasEmpty">
					<mat-icon>check</mat-icon>
				</button>
			</div>
		</div>
	</mat-card>

	<div class="buttons" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
		<button id="signature-modal-btn-submit" mat-button style='margin-right:5px' color="primary">{{ 'SAVE' | translate }}</button>
		<button id="signature-modal-btn-cancel" mat-button (click)="dialogClose()">{{ 'CANCEL' | translate }}</button>  
	</div>

</div>