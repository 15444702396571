import { Injectable } from '@angular/core';
import { NgxIndexedDBService, WithID } from 'ngx-indexed-db';
import { DecryptorPipe, encrypt, decrypt, decryptPermissions, EncryptorPipe } from '../../pipes/encryptors'
import { keyBy } from 'lodash';
import { promise } from 'protractor';
import { CryptoService } from '../encryption/crypto.service';
import { DataStore } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class AcpIndexDBService {

  constructor(
    private dbService: NgxIndexedDBService,
    private cryptoService: CryptoService
  ) { }


  //Insert List
  public async processToIdbList(tableName, res, user_id, column_name) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find(entry => entry.id === user_id);

    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
    }
    const dataPush: any[] = [];
    for (const entry of res) {
      const encryptedData = await encrypt(entry)
      dataPush.push(encryptedData)
    }
    const dataPass = {
      id: user_id,
      [column_name]: dataPush
    };
    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedEntriesList(tableName: string, user_id, column_name): Promise<any[]> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const userEntry = entries.find(entry => entry.id === user_id);

      if (!userEntry) {
        return [];
      }

      const decryptedResults = await Promise.all(
        userEntry[column_name].map(entry => decrypt(entry))
      );

      return decryptedResults;
    } catch (error) {
      console.error(`Error fetching decrypted entries from ${tableName}:`, error);
      return [];
    }
  }



  //Insert an id and encrypted object, also delete only the user_id given
  public async processToIDB(tableName, res, encryptFields = [], user_id, column_name) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find(entry => entry.id === user_id);

    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
    }

    const datapush = [];
    for (const item of res) {
      const dataToStore = {};
      for (const [key, value] of Object.entries(item)) {
        if (encryptFields.includes(key)) {
          dataToStore[key] = value !== null ? await encrypt(String(value)) : null;
        } else {
          dataToStore[key] = value;
        }
      }
      datapush.push(dataToStore);
    }

    const dataPass = {
      id: user_id,
      [column_name]: datapush
    };
    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedEntries(tableName, decryptFields, user_id, column_name,): Promise<any[]> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const decryptFieldSet = new Set(decryptFields);

      const userEntriesMap = new Map(entries.map(entry => [entry.id, entry]));
      const userEntry = userEntriesMap.get(user_id);
      if (!userEntry) {
        return [];
      }
      const decryptedResults = await Promise.all(
        userEntry[column_name].map(async (entry: any) => {
          const decryptedEntry: any = {};
          await Promise.all(
            Object.entries(entry).map(async ([key, value]) => {
              if (decryptFieldSet.has(key)) {
                decryptedEntry[key] = value !== null ? await decrypt(value as ArrayBuffer) : null;
              }
            })
          );
          return decryptedEntry;
        })
      );
      return decryptedResults;
    } catch (error) {
      console.error(`Error fetching decrypted entries from ${tableName}:`, error);
      return [];
    }
  }


  //Encrypt all
  public async processToIdbV2(tableName, res, encryptFields = []) {
    await this.dbService.clear(tableName).toPromise();
    for (const item of res) {
      const dataToStore = {};
      for (const [key, value] of Object.entries(item)) {
        if (encryptFields.includes(key)) {
          dataToStore[key] = value !== null ? await encrypt(String(value)) : null;
        }
      }
      try {
        const key = await this.dbService.add(tableName, dataToStore).toPromise();
      } catch (error) {
        console.log(`Error adding item to ${tableName}:`, error);
      }
    }
  }


  //Decrypt All
  public async getDecryptedEntriesV2(tableName: string, decryptFields: string[]): Promise<any[]> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();

      const decryptedResults = await Promise.all(entries.map(async (entry) => {
        const decryptedEntry: any = {};

        // Decrypt only the specified fields, but retain all fields
        await Promise.all(Object.entries(entry).map(async ([key, value]) => {
          if (decryptFields.includes(key)) {
            decryptedEntry[key] = value !== null ? await decrypt(value as ArrayBuffer) : null;
          }
        }));

        return decryptedEntry;
      }));

      return decryptedResults;
    } catch (error) {
      console.error(`Error fetching decrypted entries from ${tableName}:`, error);
      return [];
    }
  }


  //For single Objects
  public async processToIdbV3(tableName, res, encryptFields = []) {
    await this.dbService.clear(tableName).toPromise();

    const dataToStore = {}
    for (const [key, value] of Object.entries(res)) {
      if (encryptFields.includes(key)) {
        dataToStore[key] = value !== null ? await encrypt(String(value)) : null
        // dataToStore[key] = value
      }
    }
    try {
      const key = await this.dbService.add(tableName, dataToStore).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedEntriesV3(tableName: string, decryptFields: string[]): Promise<any> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      if (entries.length === 0) {
        return {};
      }

      const firstEntry = entries[0];
      const decryptedEntry = await Promise.all(
        Object.entries(firstEntry).map(async ([key, value]) => {
          if (decryptFields.includes(key)) {
            return [key, value !== null ? await decrypt(value as ArrayBuffer) : null];
          }
        })
      );

      return Object.fromEntries(decryptedEntry);
    } catch (error) {
      console.error(`Error fetching or decrypting entries from ${tableName}:`, error);
      return {};
    }
  }


  public async processToIdbPermissions(tableName, res, encryptFields = []) {
    await this.dbService.clear(tableName).toPromise();
    for (const item of res) {
      const dataToStore = {};
      for (const [key, value] of Object.entries(item)) {
        if (encryptFields.includes(key)) {
          if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
            dataToStore[key] = {};
            for (const [nestedKey, nestedValue] of Object.entries(value)) {
              dataToStore[key][nestedKey] = await encrypt(String(nestedValue));
            }
          } else {
            dataToStore[key] = await encrypt(String(value));
          }
        }
      }
      try {
        const key = await this.dbService.add(tableName, dataToStore).toPromise();
      } catch (error) {
        console.log(`Error adding item to ${tableName}:`, error);
      }
    }
  }

  public async processToIdbDoctorProducts(tableName, res, encryptFields = [], user_id, column_name) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find(entry => entry.id === user_id);

    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
    }
    const datapush = [];
    for (const item of res) {
      const dataToStore = [];
      for (const [key, value] of Object.entries(item)) {
        if (encryptFields.includes(key)) {
          if (Array.isArray(value)) {
            dataToStore[key] = [];
            for (const [nestedKey, nestedValue] of Object.entries(value)) {
              if (nestedValue !== null && typeof nestedValue === 'object' && !Array.isArray(nestedValue)) {
                dataToStore[key][nestedKey] = {}
                for (const [lastKey, lastValue] of Object.entries(nestedValue)) {
                  dataToStore[key][nestedKey][lastKey] = await encrypt(String(lastValue));
                }
              }
            }
          } else {
            dataToStore[key] = value;
          }
        }
      }
      datapush.push(dataToStore);
    }
    const dataPass = {
      id: user_id,
      [column_name]: datapush
    };
    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedEntriesDoctorProductsSingle(tableName, user_id, column_name, doctor_id): Promise<any> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();

      const userEntriesMap = new Map(entries.map(entry => [entry.id, entry]));
      const userEntry = userEntriesMap.get(user_id);
      if (!userEntry) {
        return {};
      }

      const doctorEntriesMap = new Map(userEntry[column_name].map(entry => [entry.doctor_id, entry]));
      const doctorEntry = doctorEntriesMap.get(doctor_id)
      if (!doctorEntry) {
        return {};
      }

      const decryptedResults = await Promise.all(
        doctorEntry['output'].map(async (entry: any) => {
          const decryptedEntry: any = {};
          await Promise.all(
            Object.entries(entry).map(async ([key, value]) => {
              decryptedEntry[key] = value !== null ? await decrypt(value as ArrayBuffer) : null;
            })
          );
          return decryptedEntry;
        })
      );
      return decryptedResults;
    } catch (error) {
      console.error(`Error fetching decrypted entries from ${tableName}:`, error);
      return {};
    }
  }

  public async getDecryptedEntriesDoctorProducts(tableName, user_id, decryptFields = [], column_name): Promise<any> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const userEntriesMap = new Map(entries.map(entry => [entry.id, entry]));
      const userEntry = userEntriesMap.get(user_id);
      if (!userEntry) {
        return {};
      }

      const dataToDecrypt = userEntry[column_name];
      const decryptedData = [];
      await Promise.all(dataToDecrypt.map(async (item) => {
        const decryptedItem = {};

        await Promise.all(Object.entries(item).map(async ([key, value]) => {
          if (decryptFields.includes(key)) {
            if (Array.isArray(value)) {
              decryptedItem[key] = await Promise.all(value.map(async (nestedValue) => {
                if (nestedValue !== null && typeof nestedValue === 'object' && !Array.isArray(nestedValue)) {
                  const decryptedObj = {};
                  await Promise.all(Object.entries(nestedValue).map(async ([lastKey, lastValue]) => {
                    decryptedObj[lastKey] = lastValue !== null ? await decrypt(lastValue as ArrayBuffer) : null;
                  }));
                  return decryptedObj;
                }
                return nestedValue;
              }));
            } else {
              decryptedItem[key] = value !== null ? value : null;
            }
          } else {
            decryptedItem[key] = value;
          }
        }));

        decryptedData.push(decryptedItem);
      }));

      return decryptedData;


    } catch (error) {
      console.error(`Error fetching decrypted entries from ${tableName}:`, error);
      return {};
    }
  }

  public async getDecryptedEntriesPermissions(tableName: string, decryptFields: string[]): Promise<any[]> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const decryptedResults = await Promise.all(entries.map(async (entry) => {
        const decryptedEntry: any = {};
        await Promise.all(Object.entries(entry).map(async ([key, value]) => {
          if (decryptFields.includes(key) && value !== null && typeof value === 'object' && !Array.isArray(value)) {
            decryptedEntry[key] = await decryptPermissions(value as ArrayBuffer);
            if (decryptedEntry[key] == "") {
              decryptedEntry[key] = {};
              await Promise.all(Object.entries(value).map(async ([nestedKey, nestedValue]) => {
                decryptedEntry[key][nestedKey] = nestedValue !== null ? await decrypt(nestedValue as ArrayBuffer) : null;
              }));
            }
          }
        }));
        return decryptedEntry;
      }));
      return decryptedResults;
    } catch (error) {
      console.error(`Error fetching decrypted entries from ${tableName}:`, error);
      return [];
    }
  }

  public async processToIdbIncidental(tableName, res, encryptFields = [], user_id, column_name) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = await entries.find(entry => entry.id === user_id);
    const datapush = [];
    const dataToStore = {};
    for (const [key, value] of Object.entries(res)) {
      if (encryptFields.includes(key)) {
        dataToStore[key] = value !== null ? await encrypt(String(value)) : null;
      } else {
        dataToStore[key] = value;
      }
    }
    datapush.push(dataToStore);
    const dataPass = {
      id: user_id,
      [column_name]: datapush
    };

    try {
      if (existingEntry) {
        const existingData = existingEntry[column_name] || [];
        existingData.push(...datapush);
        const updatedEntry = {
          ...existingEntry,
          [column_name]: existingData
        };
        await this.dbService.update(tableName, updatedEntry).toPromise();
      } else {
        await this.dbService.add(tableName, dataPass).toPromise();
      }
    } catch (error) {
      console.log(`Error adding/updating item in ${tableName}:`, error);
    }
  }


  public async processToIdbProducts(tableName, res, encryptFields = []) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    if (tableName == 'acp_products') {
      const matchingEntries = entries.filter(entry => {
        const doctorId = entry[0].doctor_id;
        const acpDate = entry[0].acp_date;
        return doctorId == res.doctor_id && acpDate == res.acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }

    if (tableName == 'acp_products_planned') {
      const matchingEntries = entries.filter(entry => {
        const id = entry[0].id;
        const acpDate = entry[0].acp_date;
        return id == res.id && acpDate == res.acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }



    const datapush = []
    const dataObject = {}
    for (const [key, value] of Object.entries(res)) {
      if (encryptFields.includes(key)) {
        if (Array.isArray(value)) {
          dataObject[key] = [];
          for (const [nestedKey, nestedValue] of Object.entries(value)) {
            if (nestedValue !== null && typeof nestedValue === 'object' && !Array.isArray(nestedValue)) {
              dataObject[key][nestedKey] = {}
              for (const [lastKey, lastValue] of Object.entries(nestedValue)) {
                dataObject[key][nestedKey][lastKey] = await encrypt(String(lastValue));
              }
            }
          }
        } else {
          dataObject[key] = value;
        }
      }
    }
    datapush.push(dataObject)
    try {
      const key = await this.dbService.add(tableName, datapush).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedEntriesAllProductNotes(tableName, decryptFields): Promise<any[]> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const decryptedData: any[] = [];

      // Decrypt data for all entries
      await Promise.all(entries.map(async (item) => {
        const decryptedItem: any = {};
        await Promise.all(Object.entries(item).map(async ([key, value]) => {
          if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
            await Promise.all(Object.entries(value).map(async ([nestedKey, nestedValue]) => {
              if (decryptFields.includes(nestedKey)) {
                if (Array.isArray(nestedValue)) {
                  // Handle array of objects
                  decryptedItem[nestedKey] = await Promise.all(nestedValue.map(async (lastItem) => {
                    if (lastItem !== null && typeof lastItem === 'object' && !Array.isArray(lastItem)) {
                      const decryptedLastItem: any = {};
                      await Promise.all(Object.entries(lastItem).map(async ([finalKey, finalValue]) => {
                        decryptedLastItem[finalKey] = finalValue !== null ? await decrypt(finalValue as ArrayBuffer) : null;
                      }));
                      return decryptedLastItem; // Return decrypted last item
                    }
                    return lastItem; // If not an object, return as is
                  }));
                } else {
                  // Handle single value
                  decryptedItem[nestedKey] = nestedValue;
                }
              }
            }));
          }
        }));
        decryptedData.push(decryptedItem); // Push the decrypted item to the array
      }));

      return decryptedData; // Return the decrypted data

    } catch (error) {
      console.error(`Error fetching decrypted entries from ${tableName}:`, error);
      return [];
    }
  }



  public async getDecryptedEntriesProductNotes(tableName, doctor_id, acp_date, decryptFields): Promise<any> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      let matchingEntries
      if (tableName == 'acp_products' || tableName == 'acp_notes') {
        matchingEntries = entries.filter(entry => {
          const doctorId = entry[0].doctor_id;
          const acpDate = entry[0].acp_date;
          return doctorId == doctor_id && acpDate == acp_date;
        });
      } else {
        matchingEntries = entries.filter(entry => {
          const doctorId = entry[0].id;
          const acpDate = entry[0].acp_date;
          return doctorId == doctor_id && acpDate == acp_date;
        });
      }

      if (!matchingEntries) {
        return {}
      }

      if (!matchingEntries[0]) {
        return {}
      }

      const decryptedData = []
      await Promise.all(matchingEntries[0].map(async (item) => {
        const decryptedItem = {}
        await Promise.all(Object.entries(item).map(async ([key, value]) => {
          if (decryptFields.includes(key)) {
            if (Array.isArray(value)) {
              decryptedItem[key] = await Promise.all(value.map(async (nestedValue) => {
                if (nestedValue !== null && typeof nestedValue === 'object' && !Array.isArray(nestedValue)) {
                  const decryptedObj = {};
                  await Promise.all(Object.entries(nestedValue).map(async ([lastKey, lastValue]) => {
                    decryptedObj[lastKey] = lastValue !== null ? await decrypt(lastValue as ArrayBuffer) : null;
                  }));
                  return decryptedObj;
                }
                return nestedValue;
              }))
            } else {
              decryptedItem[key] = value
            }
          }
        }));
        decryptedData.push(decryptedItem);
      }))
      return decryptedData;

    } catch (error) {
      console.error(`Error fetching decrypted entries from ${tableName}:`, error);
      return {};
    }
  }

  public async processToIdbNotes(tableName, res, encryptFields = []) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    if (tableName == 'acp_notes') {
      const matchingEntries = entries.filter(entry => {
        const doctorId = entry[0].doctor_id;
        const acpDate = entry[0].acp_date;
        return doctorId == res.doctor_id && acpDate == res.acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    } else {
      const matchingEntries = entries.filter(entry => {
        const id = entry[0].id;
        const acpDate = entry[0].acp_date;
        return id == res.id && acpDate == res.acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }

    const datapush = []
    const dataObject = {}
    for (const [key, value] of Object.entries(res)) {
      if (encryptFields.includes(key)) {
        if (Array.isArray(value)) {
          dataObject[key] = [];
          for (const [nestedKey, nestedValue] of Object.entries(value)) {
            if (nestedValue !== null && typeof nestedValue === 'object' && !Array.isArray(nestedValue)) {
              dataObject[key][nestedKey] = {}
              for (const [lastKey, lastValue] of Object.entries(nestedValue)) {
                dataObject[key][nestedKey][lastKey] = await encrypt(String(lastValue));
              }
            }
          }
        } else {
          dataObject[key] = value;
        }
      }
    }
    datapush.push(dataObject)
    try {
      const key = await this.dbService.add(tableName, datapush).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }


  public async saveToIDB(tableName: string, newData: any[], user_id: number, column_name) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find(entry => entry.id === user_id);
    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
    }

    const datapush = [];
    for (const item of newData){
      const dataToStore = {}
      const itemImage = item.imageFile
      const trylang = JSON.stringify(item)
      dataToStore['data'] = await this.cryptoService.encryptDataBased(trylang)
      dataToStore['imageFile'] = itemImage
      datapush.push(dataToStore)
    }

    const dataPass = {
      id: user_id,
      [column_name]: datapush,
    };

    // const datapush = [];
    // for (const item of newData) {
    //   const dataToStore = {};
    //   for (const [key, value] of Object.entries(item)) {
    //     if (Array.isArray(value)) {
    //       dataToStore[key] = await Promise.all(value.map(async (nestedItem) => {
    //         const processedNestedItem = {};
    //         for (const [nestedKey, nestedValue] of Object.entries(nestedItem)) {
    //           processedNestedItem[nestedKey] = nestedValue !== null ? await encrypt(String(nestedValue)) : null;
    //         }
    //         return processedNestedItem;
    //       }));
    //     } else if (typeof value === 'object' && value !== null) {
    //       const processedObject = {};
    //       for (const [nestedKey, nestedValue] of Object.entries(value)) {
    //         processedObject[nestedKey] = nestedValue !== null ? await encrypt(String(nestedValue)) : null;
    //       }
    //       dataToStore[key] = processedObject;
    //     } else {
    //       dataToStore[key] = value;
    //     }
    //   }
    //   dataToStore['imageFile'] = imageFile
    //   datapush.push(dataToStore);
    // }

    // const dataPass = {
    //   id: user_id,
    //   [column_name]: datapush,
    // };


    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }


  public async loadFromIDB(tableName: string, user_id: number, column_name: string) {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const existingEntry = entries.find(entry => entry.id === user_id);

      if (!existingEntry || !existingEntry[column_name]) {
        return [];
      }
      const decryptedData = [];
      for (const item of existingEntry[column_name]) {
        const itemToReturn = {}
        for (const [key, value] of Object.entries(item)){
          if (key == 'imageFile'){
            itemToReturn['imageFile'] = value
          } else if (key == 'data') {
            const jsonParse = JSON.parse(await this.cryptoService.decryptDataBased(String(value)))
            for (const [jsonKey, jsonValue] of Object.entries(jsonParse)){
              itemToReturn[jsonKey] = jsonValue
            }
          }
        }
        decryptedData.push(itemToReturn)
      }

      // const decryptedData = [];
      // for (const item of existingEntry[column_name]) {
      //   const dataToLoad = {};

      //   for (const [key, value] of Object.entries(item)) {
      //     if (Array.isArray(value)) {
      //       dataToLoad[key] = await Promise.all(value.map(async (nestedItem) => {
      //         const decryptedNestedItem = {};
      //         for (const [nestedKey, nestedValue] of Object.entries(nestedItem)) {
      //           decryptedNestedItem[nestedKey] = nestedValue !== null ? await decrypt(nestedValue as ArrayBuffer) : null;
      //         }
      //         return decryptedNestedItem;
      //       }));
      //     } else if (typeof value === 'object' && value !== null) {
      //       if (key === 'imageFile') {
      //         // Return imageFile as-is
      //         dataToLoad[key] = value;
      //       } else {
      //         const decryptedObject = {};
      //         for (const [nestedKey, nestedValue] of Object.entries(value)) {
      //           decryptedObject[nestedKey] = nestedValue !== null ? await decrypt(nestedValue as ArrayBuffer) : null;
      //         }
      //         dataToLoad[key] = decryptedObject;
      //       }
      //     } else {
      //       dataToLoad[key] = value;
      //     }
      //   }

      //   decryptedData.push(dataToLoad);
      // }

      return decryptedData;

    } catch (error) {
      console.log(`Error loading data from ${tableName}:`, error);
      return null;
    }
  }





  public async deleteAcpProductNotes(tableName, doctor_id, acp_date) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    if (tableName == 'acp_products' || tableName == 'acp_notes') {
      const matchingEntries = entries.filter(entry => {
        const doctorId = entry[0].doctor_id;
        const acpDate = entry[0].acp_date;
        return doctorId == doctor_id && acpDate == acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    } else {
      const matchingEntries = entries.filter(entry => {
        const id = entry[0].id;
        const acpDate = entry[0].acp_date;
        return id == doctor_id && acpDate == acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }
  }


  public async deleteAcpProductNotesId(tableName, doctor_id) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    if (tableName == 'acp_products' || tableName == 'acp_notes') {
      const matchingEntries = entries.filter(entry => {
        const doctorId = entry[0].doctor_id;
        return doctorId == doctor_id
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    } else {
      const matchingEntries = entries.filter(entry => {
        const id = entry[0].id;
        return id == doctor_id
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }
  }



  public async clearTable(tableName: string) {
    try {
      await this.dbService.clear(tableName).toPromise();
      console.log(`Table "${tableName}" cleared successfully.`);
    } catch (error) {
      if (error.name === 'NotFoundError') {
        console.warn(`Table "${tableName}" does not exist, skipping.`);
      } else {
        console.error(`Error clearing "${tableName}" table:`, error);
      }
    }
  }

  public compareSettings(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) return false;

    const compareObjects = (obj1: any, obj2: any): boolean => {
      if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return obj1 == obj2;
      }

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;

      for (const key of keys1) {
        const value1 = obj1[key];
        const value2 = obj2[key];
        if (!compareObjects(value1, value2)) {
          return false; // 
        }
      }
      return true;
    };

    const sortedArr1 = [...arr1].sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
    const sortedArr2 = [...arr2].sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));

    for (let i = 0; i < sortedArr1.length; i++) {
      if (!compareObjects(sortedArr1[i], sortedArr2[i])) {
        return false;
      }
    }

    return true;
  }

  public async deleteUserEntry(tableName, user_id) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find(entry => entry.id === user_id);

    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
    }
  }

  public async GetDate() {
    const currentDate = new Date();
    const utcPlus8Date = new Date(currentDate.getTime() + (8 * 60 * 60 * 1000));
    return utcPlus8Date.toISOString().split('T')[0];
  }

  public getCurrentDateSQLFormat() {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  public async processToIdbActivityDetail(tableName, res, encryptFields = []) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();

    const matchingEntries = entries.filter(entry => {
      const id = entry[0].id;
      const acpDate = entry[0].acp_date;
      return id == res.id && acpDate == res.acp_date;
    });
    for (const entry of matchingEntries) {
      const entryId = entry.id;
      await this.dbService.delete(tableName, entryId).toPromise();
    }

    const datapush = []
    const dataObject = {}
    for (const [key, value] of Object.entries(res)) {
      if (encryptFields.includes(key)) {
        dataObject[key] = await encrypt(String(value))
      } else {
        dataObject[key] = value;
      }
    }
    datapush.push(dataObject)

    try {
      const key = await this.dbService.add(tableName, datapush).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedEntriesActivityDetail(tableName, decryptFields): Promise<any> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const decryptedData: any[] = [];

      // Decrypt data for all entries
      await Promise.all(entries.map(async (item) => {
        const decryptedItem: any = {};
        await Promise.all(Object.entries(item).map(async ([key, value]) => {
          if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
            await Promise.all(Object.entries(value).map(async ([nestedKey, nestedValue]) => {
              if (decryptFields.includes(nestedKey)) {
                decryptedItem[nestedKey] = await decrypt(nestedValue as ArrayBuffer);
              } else {
                decryptedItem[nestedKey] = nestedValue;
              }
            }));
          }
        }));
        decryptedData.push(decryptedItem);
      }));

      return decryptedData;

    } catch (error) {
      console.error(`Error fetching decrypted entries from ${tableName}:`, error);
      return [];
    }
  }


  public async deleteActivityDetail(tableName, res) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const matchingEntries = entries.filter(entry => {
      const id = entry[0].id;
      const acpDate = entry[0].acp_date;
      return id == res.id && acpDate == res.acp_date;
    });
    for (const entry of matchingEntries) {
      const entryId = entry.id;
      await this.dbService.delete(tableName, entryId).toPromise();
    }
  }


  public async processAESToIDB(tableName, res, user_id, column_name) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find(entry => entry.id === user_id);

    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
    }

    if(res.length == 0){
      return []
    }

    const testTO = JSON.stringify(res);
    // const testEncrypted = await encrypt(String(res))
    const testEncrypted = await this.cryptoService.encryptDataBased(testTO);
    const dataPass = {
      id: user_id,
      [column_name]: testEncrypted
    }

    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async getDecryptedAesEntries(tableName, user_id, column_name) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const userEntriesMap = new Map(entries.map(entry => [entry.id, entry]));
    const userEntry = userEntriesMap.get(user_id);
    if (!userEntry || userEntry == undefined) {
      return [];
    }

    if (userEntry[column_name].length <= 0) {
      return [];
    }

    const decryptedData = await this.cryptoService.decryptDataBased(userEntry[column_name])
    return JSON.parse(decryptedData)
  }


  public async processAESToIDBIncidental(tableName, res, user_id, column_name) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    const existingEntry = entries.find(entry => entry.id === user_id);
    let dataExisting = []
    let toBePush = ""
    if (existingEntry) {
      await this.dbService.delete(tableName, user_id).toPromise();
      let existingData = await this.cryptoService.decryptDataBased(existingEntry[column_name])
      dataExisting = JSON.parse(existingData)
      dataExisting.push(res)
      toBePush = await this.cryptoService.encryptDataBased(JSON.stringify(dataExisting))
    } else {
      dataExisting.push(res)
      toBePush = await this.cryptoService.encryptDataBased(JSON.stringify(dataExisting))
    }

    const dataPass = {
      id: user_id,
      [column_name]: toBePush
    }

    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }

  }

  public async processToIdbProductsAes(tableName, res) {
    const entries: any[] = await this.dbService.getAll(tableName).toPromise();
    if (tableName == 'acp_products') {
      const matchingEntries = entries.filter(entry => {
        const doctorId = entry[0].doctor_id;
        const acpDate = entry[0].acp_date;
        return doctorId == res.doctor_id && acpDate == res.acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }

    if (tableName == 'acp_products_planned') {
      const matchingEntries = entries.filter(entry => {
        const id = entry[0].id;
        const acpDate = entry[0].acp_date;
        return id == res.id && acpDate == res.acp_date;
      });
      for (const entry of matchingEntries) {
        const entryId = entry.id;
        await this.dbService.delete(tableName, entryId).toPromise();
      }
    }
  }


  public async storeVersion(tableName, res){
    const dataPass = {
      id: 1,
      version: res
    }

    try {
      const key = await this.dbService.add(tableName, dataPass).toPromise();
    } catch (error) {
      console.log(`Error adding item to ${tableName}:`, error);
    }
  }

  public async updateVersion(tableName: string, res: string) {
    const dataPass = {
      id: 1,
      version: res
    };
  
    try {
      const key = await this.dbService.update(tableName, dataPass).toPromise();
      console.log(`Version updated for ${tableName} with key:`, key);
    } catch (error) {
      console.log(`Error updating version for ${tableName}:`, error);
    }
  }
  
  
  

  public async getStoredVersion(): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      this.dbService.getByID('ngsw_version', 1).subscribe({
        next: (result) => {
          resolve(result ? (result as any).version : undefined);
        },
        error: (error) => {
          console.error("Error fetching stored version:", error);
          reject(error);
        },
      });
    });
  }
  


}



// public async processToIdbProducts(tableName, res, encryptFields = []) {


//   const datapush = []
//   const dataObject = {}
//   for (const [key, value] of Object.entries(res)) {
//     if (encryptFields.includes(key)) {
//       if (Array.isArray(value)) {
//         dataObject[key] = [];
//         for (const [nestedKey, nestedValue] of Object.entries(value)) {
//           if (nestedValue !== null && typeof nestedValue === 'object' && !Array.isArray(nestedValue)) {
//             dataObject[key][nestedKey] = {}
//             for (const [lastKey, lastValue] of Object.entries(nestedValue)) {
//               dataObject[key][nestedKey][lastKey] = await encrypt(String(lastValue));
//             }
//           }
//         }
//       } else {
//         dataObject[key] = value;
//       }
//     }
//   }
//   datapush.push(dataObject)
//   try {
//     const key = await this.dbService.add(tableName, datapush).toPromise();
//   } catch (error) {
//     console.log(`Error adding item to ${tableName}:`, error);
//   }
// }












// public async getDecryptedEntries(tableName, decryptFields, user_id, column_name,): Promise<any[]> {
//   try {
//     const entries: any[] = await this.dbService.getAll(tableName).toPromise();
//     const decryptFieldSet = new Set(decryptFields);

//     const userEntriesMap = new Map(entries.map(entry => [entry.id, entry]));
//     const userEntry = userEntriesMap.get(user_id);
//     if (!userEntry) {
//       return [];
//     }
//     const decryptedResults = await Promise.all(
//       userEntry[column_name].map(async (entry: any) => {
//         const decryptedEntry: any = {};
//         await Promise.all(
//           Object.entries(entry).map(async ([key, value]) => {
//             if (decryptFieldSet.has(key)) {
//               decryptedEntry[key] = value !== null ? await decrypt(value as ArrayBuffer) : null;
//             }
//           })
//         );
//         return decryptedEntry;
//       })
//     );
//     return decryptedResults;
//   } catch (error) {
//     console.error(`Error fetching decrypted entries from ${tableName}:`, error);
//     return [];
//   }
// }









//Decrypt A List
// public async getDecryptedEntriesList(tableName: string, user_id, column_name): Promise<any[]> {
//   try {
//     const entries: any[] = await this.dbService.getAll(tableName).toPromise();
//     const decryptedResults: any[] = [];
//     const userEntry = entries.find(entry => entry.id === user_id);

//     if (!userEntry) {
//       return [];
//     }

//     for (const entry of userEntry[column_name]) {
//       decryptedResults.push(await decrypt(entry))
//     }

//     return decryptedResults;
//   } catch (error) {
//     console.error(`Error fetching decrypted entries from ${tableName}:`, error);
//     return [];
//   }
// }



//Decrypt for Single Objects
// public async getDecryptedEntriesV3(tableName: string, decryptFields: string[]): Promise<any[]> {
//   try {
//     const entries: any[] = await this.dbService.getAll(tableName).toPromise();
//     const decryptedEntry: any = {};
//     for (const [key, value] of Object.entries(entries[0])) {
//       if (decryptFields.includes(key)) {
//         decryptedEntry[key] = value !== null ? await decrypt(value as ArrayBuffer) : null;
//       }
//     }

//     return decryptedEntry;
//   } catch (error) {
//     return [];
//   }
// }



// public async getDecryptedEntriesPermissions(tableName: string, decryptFields: string[]): Promise<any[]> {
//   try {
//     const entries: any[] = await this.dbService.getAll(tableName).toPromise();
//     const decryptedResults: any[] = [];

//     for (const entry of entries) {
//       const decryptedEntry: any = {};
//       for (const [key, value] of Object.entries(entry)) {
//         if (decryptFields.includes(key)) {
//           if(value !== null && typeof value === 'object' && !Array.isArray(value)){
//             decryptedEntry[key] = await decryptPermissions(value as ArrayBuffer);
//             if(decryptedEntry[key] == ""){
//               decryptedEntry[key] = {};
//               for (const [nestedKey, nestedValue] of Object.entries(value)) {
//                 decryptedEntry[key][nestedKey] = value !== null ? await decrypt(nestedValue as ArrayBuffer) : null;
//               }
//             }
//           }
//         }
//       }
//       decryptedResults.push(decryptedEntry);
//     }
//     return decryptedResults;
//   } catch (error) {
//     console.error(`Error fetching decrypted entries from ${tableName}:`, error);
//     return [];
//   }
// }


//Decrypt the data which has the user_id
// public async getDecryptedEntries(tableName: string, decryptFields: string[], user_id, column_name): Promise<any[]> {
//   try {
//     const entries: any[] = await this.dbService.getAll(tableName).toPromise();
//     const decryptedResults: any[] = [];
//     const userEntry = entries.find(entry => entry.id === user_id);

//     if (!userEntry) {
//       return [];
//     }

//     for (const entry of userEntry[column_name]) {
//       const decryptedEntry: any = {};
//       for (const [key, value] of Object.entries(entry)) {
//         if (decryptFields.includes(key)) {
//           decryptedEntry[key] = value !== null ? await decrypt(value as ArrayBuffer) : null;
//         }
//       }
//       decryptedResults.push(decryptedEntry);
//     }
//     return decryptedResults;
//   } catch (error) {
//     console.error(`Error fetching decrypted entries from ${tableName}:`, error);
//     return [];
//   }
// }

// public async getDecryptedEntriesV2(tableName: string, decryptFields: string[]): Promise<any[]> {
//   try {
//     const entries: any[] = await this.dbService.getAll(tableName).toPromise();
//     const decryptedResults: any[] = [];

//     for (const entry of entries) {
//       const decryptedEntry: any = {};
//       for (const [key, value] of Object.entries(entry)) {
//         if (decryptFields.includes(key)) {
//           decryptedEntry[key] = value !== null ? await decrypt(value as ArrayBuffer) : null;
//         }
//       }
//       decryptedResults.push(decryptedEntry);
//     }
//     return decryptedResults;
//   } catch (error) {
//     console.error(Error fetching decrypted entries from ${tableName}:, error);
//     return [];
//   }
// }