export class Path {
  public static readonly LOGIN = {
    GET_USER_DETAILS: "/web-login/sync",
    GET_USERPOOL: "dev/license/get-user-pool",
    GET_USERPOOL2: "dev/license-2/get-user-pool",
    UPSERT_TRANSACTION: "dev/user-transaction-web/upsert_last_login",
    GET_LANDING_PAGE_STATUS: "dev/user-transaction-web/get_landing_status",
    UPDATE_LANDING_PAGE_STATUS: "dev/user-transaction-web/update_landing_status",
    GET_APP_USER: "dev/user-transaction-web/get_user",
    UPSERT_PASSWORD_EXPIRY: "dev/user-transaction-web/upsert-password-expiry",
    GET_PASSWORD_EXPIRY: "dev/user-transaction-web/get-password-expiry",
  };

  public static readonly ACCOUNT_SETTINGS = {
    GET_LIST: "/host-app-setting/sync",
    SAVE_SETTINGS: "/host-app-setting/sync"
  };

  public static readonly ACCOUNT = {
    GET_LIST: "/principals"
  }

  public static readonly CHANNEL = {
    GET_LIST: "/channels"
  }

  public static readonly REGION = {
    GET_LIST: "/regions"
  }

  public static readonly ROLE = {
    GET_LIST: "/roles",
    ADD: "",
    UPDATE: ""
  }

  public static readonly STORE = {
    GET_LIST: "/stores",
    GET_DETAIL: "/stores",
    ADD: "/stores",
    UPDATE: "/stores",
    EXPORT: "/stores/export"
  }

  public static readonly TS = {
    GET_LIST: "/trade-supervisors"
  }

  public static readonly USER = {
    GET_LIST: "/users",
    GET_DETAIL: "/users",
    ADD: "/users",
    UPDATE: "/users",
    RESET_PASSWORD: "/users/reset-password",
    EXPORT: "/users/export",
    LIST: "/dev/user/list"
  }

  public static readonly ATTENDANCE_REPORT = {
    GET_REPORT_DATA: "/dev/attendance-report/attendance_report",
    EXPORT: "dev/attendance-report/task-runner",
    LIST_DISTRICT: "dev/attendance-report/list_district",
    LIST_TERRITORY: "dev/attendance-report/list_territory",
    LIST_USER: "dev/attendance-report/list_user"
  }
  public static readonly RED_FLAG_REPORT = {
    GET_REPORT_DATA: "/report/red-flag",
    EXPORT: "/report/red-flag/export"
  }

  public static readonly RAW_DATA_REPORT = {
    GET_COUNT: "/raw-data",
    EXPORT: "/raw-data/export"
  }

  public static readonly CUSTOMER = {
    GET_LIST: "/users",
    GET_DETAIL: "/users",
    ADD: "/users",
    UPDATE: "/users",
    RESET_PASSWORD: "/users/reset-password",
    EXPORT: "/users/export"
  }

  public static readonly KPI = {
    GET_LIST: "/users",
    GET_DETAIL: "/users",
    GET_RATE: "/dev/dashboard-host/rate",
    GET_REACH: "/dev/dashboard-host/reach",
    GET_CONCENTRATION: "/dev/dashboard-host/concentration",
    GET_CYCLE_LIST: "/dev/dashboard-host/cycle-list",
    GET_AREA_LIST: "/dev/dashboard-host/area-list",
    GET_DISTRICT_LIST: "/dev/dashboard-host/district-list",
    GET_CLASS_LIST: "/dev/dashboard-host/class-list",
    GET_SPECIALIZATION_LIST: "/dev/dashboard-host/specialization-list",
    GET_RATE_PAST_CYCLES: "/dev/dashboard-host/rate-past-cycles",
    GET_REACH_PAST_CYCLES: "/dev/dashboard-host/reach-past-cycles",
    GET_CONCENTRATION_PAST_CYCLES: "/dev/dashboard-host/concentration-past-cycles",
    GET_SUMMARY_DETAILS: "/dev/dashboard-host/call-summary-details",
    GET_SUMMARY_HEADERS: "/dev/dashboard-host/call-summary-headers",
    GET_RATE_DETAILS: "/dev/dashboard-host/call-rate-details",
    GET_REACH_DETAILS: "/dev/dashboard-host/call-reach-details",
    GET_CONCENTRATION_DETAILS: "/dev/dashboard-host/call-concentration-details",
    GENERATE_REPORT: "/dev/dashboard-host/test_email",
    GET_CYCLE_DATES: "/dev/dashboard-host/get_dates",
    GET_ACTIVITIES_DASHBOARD: "/dev/dashboard-host/get_activities",
    GET_DEVELOPMENTAL_PLAN: "/dev/dashboard-host/get_developmental_plan"

  }

  public static readonly DETAILING_AID = {
    LIST_DETAILING_AID: "dev/detailing-aid-web/list_web_detailing_aid",
    GET_DETAILING_AID: "dev/detailing-aid-web/get_web_detailing_aid",
    POST_EMAIL_DETAILING_AID: "dev/detailing-aid-web/email_web_detailing_aid",
    LIST_PRODUCT_DETAIL_AID: "dev/detailing-aid-web/get_products_detailing_aid",
    UPLOAD_DETAILING_AID: "dev/detailing-aid-web/insert_web_detailing_aid",

    LIST_MAP_DETAILING_AID_BY_DA_ID: "dev/detailing-aid-web/list_web_products_detailing_aid",
    UPLOAD_PRODUCT_DETAIL_MAP: "dev/detailing-aid-web/insert_web_product_mapping",

    LIST_PRODUCT: "dev/products-web/list_app_products",

    LIST_SPECIALIZATION: "dev/web-specialization/list_specialization",
    LIST_SPECIALIZATION_MPDA: "dev/web-specialization/list_specialization_mpda",
    LIST_DA_BY_SPECIALIZATION_MAP: "dev/detail-specialization-web/list_map_da_specialization_id",
    UPLOAD_SPECIALIZATION_DETAIL_MAP: "dev/detail-specialization-web/insert_map_detail_aid_specialization",
    GET_MDAS: "dev/detail-specialization-web/get_map_detail_aid_specialization",

    LIST_WEB_DA_BY_SPECIALIZATION: "dev/detailing-aid-web/list_web_da_by_specialization",
    LIST_WEB_DA_NOT_BY_SPECIALIZATION: "dev/detailing-aid-web/list_web_da_by_not_specialization",
  }

  public static readonly DOCTORS = {
    GET_DOCTORS: "/dev/app-doctors-list-web/get-doctors-web",
    GET_DOCTOR_INSTITUTION: "app-institutions-list-web/get-institutions-web",
    LIST_DOCTORS_FILTER: "/dev/doctor/list-doctors-filter",
    LIST_DOCTOR_INFO:"/dev/app-doctors-list-web/list_doctor_info",
    LIST_DOCTORS: "/dev/doctor-maintenance/list-doctors",
    GET_DOCTOR_DETAIL: "/dev/doctor-maintenance/get-doctor-detail",
    GET_DOCTOR_INFO: "/dev/doctor-maintenance/get-doctor-info",
    LIST_PRODUCTS: "/dev/doctor-maintenance/get-doctor-product-map",
    GET_CALL_TIME: "/dev/doctor-maintenance/get-call-time",
    ADD_DOCTOR: "/dev/doctor-maintenance/add-doctor-institution",
    LIST_DYNAMIC_OPTIONS: "/dev/doctor-maintenance/list-dynamic-info",
    LIST_PRODUCT_OPTIONS: "/dev/doctor-maintenance/list-products",
    ADD_DOCTOR_INFO: "/dev/doctor-maintenance/add-dynamic-info",
    ADD_PRODUCTS: "/dev/doctor-maintenance/add-products",
    ADD_CALL_TIME: "/dev/doctor-maintenance/add-call-time",
    GET_DOCTOR_PRIMARY_DETAILS: "/dev/doctor-maintenance/get-doctor-primary-details",
    UPDATE_DOCTOR: "/dev/doctor-maintenance/update-doctor",
    GET_DOCTOR_MAPPINGS: "/dev/doctor-maintenance/list-doctor-institution",
    UPDATE_MAPPINGS: "/dev/doctor-maintenance/update-doctor-institution",
  }


  public static readonly LANDING_PAGE = {
    LIST_IMAGES: "dev/web-landing-page/list_landing_page",
    GET_DOCTOR_INSTITUTION: "app-institutions-list-web/get-institutions-web",
  }

  public static readonly ACTIVITIES = {
    GET_ACTIVITIES: "/dev/sfe-web-get-activity/get_activity_web",
    LIST_ACTIVITIES: "/dev/activity/list"
  }

  public static readonly ATTENDANCE = {
    ADD_ATTENDANCE: "dev/attendance-web/insert_attendance",
    LIST_ATTENDANCE_PAIR: "dev/attendance-web/list_attendance_by_batch",
    LIST_ATTENDANCE_INSTITUTION: "dev/institutions-web/list_web_institution",

    GET_LATE_TIME: "dev/user-transaction-web/get_late_time",
    CHECK_ATTENDANCE_PER_DAY: "dev/attendance-web/list_attendance_in_day",

    GET_CYCLE_DATES: "dev/user-transaction-web/get_dates",
    LIST_ATTENDANCE_DETAIL_COUNT: "dev/attendance-web/list_attendance_per_cycle",

    GET_ATTENDANCE_SOLO: "dev/attendance-web/get_attendance_solo",

    GET_SERVER_TIME: "dev/attendance-web/get_server_time"
  }

  public static readonly INSTITUTION = {
    LIST_INSTITUTION: "dev/web-host-institutions/list_web_institution",
    GET_INSTITUTION: "dev/institutions-web/get_web_institution",
    TRY_INSTITUTION: "dev/institutions-device/list_app_institutions",
    LIST_INSTITUTIONS: "dev/institution-maintenance/list-institutions",
    GET_INSTITUTION_DETAIL: "dev/institution-maintenance/get-institution-detail",
    LIST_TEMP_INSTITUTIONS: "dev/institution-maintenance/list-temp-institutions",
    GET_TEMP_INSTITUTION_DETAIL: "dev/institution-maintenance/get-temp-institution-detail",
    UPSERT_INSTITUTION: "dev/institution-maintenance/upsert-institution",
    DOWNLOAD_INSTITUTIONS: "dev/institution-maintenance/task-runner",
    APPROVE_INSTITUTION: "dev/institution-maintenance/approve-institution",
    REJECT_INSTITUTION: "dev/institution-maintenance/reject-institution",
    SEARCH_INSTITUTION: "dev/institution-maintenance/search-institution",
  }

  public static readonly ACP = {
    GET_PLOTTED: "/dev/acp/get",
    GET_SETTINGS: "/dev/acp/acp-settings",
    GET_FILTER: "/dev/acp/filter",
    GET_DOC_INFO: "/dev/acp/info",
    GET_PRODUCTS: "/dev/acp/products", 
    GET_PRODUCTS_ALL: "/dev/acp/get_products_all",
    GET_REASONS: "/dev/acp/reasons",
    GET_CALL_REASONS: "/dev/acp/call_reasons",
    GET_DM: "/dev/acp/dm",
    GET_INCIDENTAL_LIST: "/dev/acp/incidental",
    GET_CALL_MODES: "/dev/acp/call-mode",
    RESCHED_MCP: "/dev/acp/resched_mcp",
    GET_TXN_INFO: "/dev/acp/get_txn_info",
    POST_ACP: "/dev/acp/post_acp",
    GET_MISSED_CALLS: "/dev/acp/get_missed_calls",
    GET_CALL_INFO: "/dev/acp/get_call_info",
    DECLARE_MISSED: "/dev/acp/declare_miss",
    GET_ACTIVITIES: "/dev/acp/get_activities",
    SEND_ACP: "/dev/acp/send_acp",
    GET_POST_CALL_TYPES: "/dev/acp/get_post_call_types",
    SAVE_TEMP: "/dev/acp/save_temp",
    GET_APP_MATERIALS: "/dev/acp/get_app_materials",
    GET_TEMP_DETAILS: "/dev/acp/get_temp_details",
    POST_ACTIVITY: "/dev/acp/post_activity",
    GET_CALL_INFO_ACTIVITY: "/dev/acp/get_call_info_activity",
    SEND_ACTIVITY: "/dev/acp/send_activity",
    GET_ACTIVITES_LIST_INCIDENTAL: "/dev/acp/get_activities_list",
    SAVE_TEMP_POST: "/dev/acp/save_temp_post_call",
    GET_TEMP_POST_DETAILS: "/dev/acp/get_temp_post_details",
    SAVE_TEMP_ACTIVITY: "/dev/acp/save_temp_activities",
    GET_TEMP_DETAILS_ACTIVITY: "/dev/acp/get_temp_details_activity",
    SAVE_TEMP_POST_ACTIVITY: "/dev/acp/save_temp_post_call_activity",
    GET_TEMP_POST_DETAILS_ACTIVITY: "/dev/acp/get_temp_post_details_activity",
    RESCHED_ACTIVITY: "/dev/acp/resched_activity",
    SAVE_DM_SIGN: "/dev/acp/save_dm_sign_doctor",
    SEND_ALL_ACP: "/dev/acp/send_all_acp"
  }

  public static readonly DM_ACP = {
    GET_USER_DETAILS: "/dev/acp-dm/info",
    GET_DM_PRODUCTS: "/dev/acp-dm/dm_products",
    GET_MATERIALS: "/dev/acp-dm/materials",
    GET_EVAL: "/dev/acp-dm/eval",
    GET_DM_DOCTORS: "/dev/acp-dm/dm_doctors",
    POST_DM_ACP: "/dev/acp-dm/post_dm_acp",
    GET_TXN_DETAILS: "/dev/acp-dm/txn_details",
    GET_PMR_LIST: "/dev/acp-dm/pmr_list",
    GET_JOINT_CALL_PLOTTED: "/dev/acp-dm/joint_call",
    POST_DM_ACTIVITY: "/dev/acp-dm/post_dm_activity",
    GET_SERVICE_CALL_PLOTTED: "/dev/acp-dm/service_call",
    GET_DM_CALL_INFO: "/dev/acp-dm/call_info"
  }

  public static readonly MCP = {
    GET_MCP_SETTINGS:"/dev/app-host-get-web-mcp-settings/list-mcp-settings",

    GET_TEMP_MCP: "/dev/sfe-host-mcp-post/list_temp_mcp",
    INSERT_TEMP_HEADER: "/dev/sfe-host-mcp-post/insert_temp_mcp_header",
    INSERT_TEMP_COMPONENT: "/dev/sfe-host-mcp-post/insert_temp_mcp",
    UPDATE_COMPONENT:"/dev/sfe-host-mcp-post/insert_temp_mcp_with_header",
    SUBMIT_COMPONENT:"/dev/sfe-host-mcp-post/insert_mcp",
    SUBMIT_HEADER:"/dev/sfe-host-mcp-post/insert_mcp_header",
    GET_MCP: "/dev/sfe-host-mcp-post/list_mcp",

    GET_TEMP_ACTIVITY_MCP: "/dev/sfe-host-mcp-post-activity/list_temp_mcp_activity", //used
    INSERT_TEMP_ACTIVITY: "/dev/sfe-host-mcp-post-activity/insert_temp_mcp_activity", //used
    INSERT_TEMP_ACTIVITY_WITH_HEADER: "/dev/sfe-host-mcp-post-activity/insert_temp_mcp_activity_with_header", //used
    SUBMIT_ACTIVITY:"/dev/sfe-host-mcp-post-activity/insert_mcp_activity", //used
    GET_ACTIVITY: "/dev/sfe-host-mcp-post-activity/list_mcp_activity",

    DELETE_MCP: "/dev/sfe-host-mcp-post/delete_mcp",
    GET_DM_MCP: "/dev/mcp-dm/list_service_call",
    GET_PMR: "/dev/mcp-dm/list_pmr"
  }

  public static readonly DM_MCP = {
    INSERT_TEMP_HEADER: "/dev/mcp-dm/insert_temp_cycle_plan_header",
    INSERT_TEMP_CYCLE_PLAN : "/dev/mcp-dm/insert_temp_cycle_plan",
    INSERT_TEMP_JOINT_CALL: "/dev/mcp-dm/insert_temp_joint_call",
    INSERT_TEMP_SERVICE_CALL: "/dev/mcp-dm/insert_temp_service_call",
    INSERT_HEADER: "/dev/mcp-dm/insert_cycle_plan_header",
    INSERT_CYCLE_PLAN:"/dev/mcp-dm/insert_cycle_plan",
    INSERT_JOINT_CALL: "/dev/mcp-dm/insert_joint_call",
    INSERT_SERVICE_CALL: "/dev/mcp-dm/insert_service_call",
    DELETE_MCP_HEADER: "/dev/mcp-dm/delete_dm_mcp_header",
    DELETE_MCP_CYCLE_PLAN: "/dev/mcp-dm/delete_dm_mcp_cycle_plan",
    DELETE_MCP_SERVICE_CALL: "/dev/mcp-dm/delete_dm_mcp_service_call",
    DELETE_MCP_JOINT_CALL: "/dev/mcp-dm/delete_dm_mcp_joint_call",
    DELETE_DM_MCP: "/dev/mcp-dm/delete_dm_mcp",
    GET_TEMP_PMR: "/dev/mcp-dm/list_temp_pmr_mcp",
    GET_TEMP_SERVICE: "/dev/mcp-dm/list_temp_service_call_mcp",
    GET_SERVICE_CALL: "/dev/mcp-dm/list_service_call_mcp",
    GET_PMR_CALL: "/dev/mcp-dm/list_pmr_mcp",
    GET_PMR_INFO: "/dev/mcp-dm/get_pmr_info"
  }

  public static readonly SO = {
    GET_INSTITUTION_DETAILS: "/dev/so/institution_details",
    GET_PAYMENT_TERMS: "/dev/so/payment_terms",
    GET_SO_PRODUCTS: "/dev/so/get_products",
    GET_BLOCKING_CONDITIONS: "/dev/so/blocking_condition",
    GET_SO_HEADERS: "/dev/so/so_headers",
    SO_UPSERT: "/dev/so/so_upsert",
    GET_SO_DETAILS: "/dev/so/so_details",
    LIST_INSTITUTIONS: "/dev/so/institutions"
  }

  public static readonly DEFAULT_SETTINGS = {
    GET_DEFAULT_SETTING: "/dev/web-default-setting/get",
    LIST_DEFAULT_SETTINGS: "/dev/web-default-setting/list",
    INSERT_DEFAULT_SETTING: "/dev/web-default-setting/insert",
    UPDATE_DEFAULT_SETTING: "/dev/web-default-setting/update",
    DELETE_DEFAULT_SETTING: "/dev/web-default-setting/delete",
    GET_BY_NAME: "/dev/web-default-setting/get_by_name",
    EXPORT_DEFAULT_SETTING: "/dev/web-default-setting/export",
  }
  
  public static readonly DOCTOR_SIGNATURE_REPORT = {
    list: "/dev/doctor-signature-report/list",
    download: "dev/doctor-signature-report/task-runner"
  }

  public static readonly ACTIVITY_REPORT = {
    list: "/dev/activity-report/list",
    download: "dev/activity-report/task-runner"
  }
  
  public static readonly DISTRICT = {
    list: "/dev/district/list"
  }

  public static readonly TERRITORY = {
    LIST_TERRITORY_BY_ID: 'dev/territory/list-territory'
  }
  
  public static readonly USER_ROLE = {
    get: "/dev/user-role/get"
  }

  public static readonly REASON = {
    list: "/dev/reason/list"
  }

  public static readonly DIVISION = {
    list: "/dev/division/list"
  }
  
  public static readonly CYCLE_NUMBER = {
    GET_CYCLE_DATES:"/dev/app-doctors-list-web/get_cycle_dates",
    GET_CYCLE_NUMBER:"/dev/app-doctors-list-web/get_cycle_number"
  }

  public static readonly CYCLE_CALENDAR = {
    LIST_CYCLE_HEADERS: "/dev/cycle-calendar/list-cycle-headers",
    LIST_CYCLE_NUMBERS: "/dev/cycle-calendar/list-cycle-numbers"
  }

  public static readonly USER_PERMISSIONS = {
    GET_USER_PERMISSIONS:"/dev/app-user-permissions/get-permissions",
    GET_WEB_MATRIX_PERMISSIONS: "/dev/security-matrix/summary"
  }
  
  public static readonly LABELS = {
    GET_MCP_LABELS: "/dev/web-default-settings-mcp-label/get_mcp_labels"
  }

  public static readonly SECURITY_MATRIX = {
    GET_SECURITY_MATRIX: "/dev/security-matrix/get",
    INSERT_SECURITY_MATRIX: "/dev/security-matrix/insert",
    LIST_SECURITY_MATRIX_HEADER: "/dev/security-matrix/header",
    UPDATE_SECURITY_MATRIX: "/dev/security-matrix/update",
    GET_TEMPLATE_MATRIX: "/dev/security-matrix/template",
  }

  public static readonly LANDING_PAGE_MAINTENANCE = {
    GET_LANDING_PAGE_IMAGE: '/dev/web-landing-page/get_maintenance',
    LIST_LANDING_PAGE_IMAGE: '/dev/web-landing-page/list_maintenance',
    INSERT_LANDING_PAGE_IMAGE: '/dev/web-landing-page/insert',
    UPDATE_LANDING_PAGE_IMAGE: '/dev/web-landing-page/update'
  }

  public static readonly MCP_APPROVAL = {
    LIST: '/dev/mcp-approval/list',
    GET_MD_COUNT: '/dev/mcp-approval/md-count',
    GET_SPECIALIZATION_COUNT: '/dev/mcp-approval/specialization-count',
    GET_CLASS_COUNT: '/dev/mcp-approval/class-count',
    GET_ACTIVITY_COUNT: '/dev/mcp-approval/activity-count',
    APPROVE: '/dev/mcp-approval/approve-mcp',
    REJECT: '/dev/mcp-approval/reject-mcp',
    DOWNLOAD_DETAILS: '/dev/mcp-approval/task-runner',
    DOWNLOAD_LIST: '/dev/mcp-approval-export/task-runner'
  }

  public static readonly MISSED_CALL_APPROVAL = {
    LIST: '/dev/missed-call-approval/list',
    GET: '/dev/missed-call-approval/get',
    ACCEPT: '/dev/missed-call-approval/accept',
    REJECT: '/dev/missed-call-approval/reject',
    EXPORT: '/dev/missed-call-approval/task-runner'
  }

  public static readonly STATUS_FLAGS = {
    LIST_MCP_STATUSES: "/dev/status-flags/list-mcp-statuses",
    LIST_APPROVAL_STATUSES: "/dev/status-flags/list-approval-statuses"
  }
  public static readonly PEDA_REPORT = {
    GET_QS: "/dev/quicksight-host/get-qs-embed-url",
    
    //SAVE MCP
  }

  public static readonly DOCTOR_APPROVAL = {
    LIST: '/dev/doctor-approval/list',
    LIST_MDI: '/dev/doctor-approval/list-mdi',
    GET: '/dev/doctor-approval/get-details-for-comparison',
    APPROVE: '/dev/doctor-approval/approve-doctor',
    DISAPPROVE: '/dev/doctor-approval/disapprove-doctor',
    DOWNLOAD_LIST: '/dev/doctor-approval/task-runner',
    APPROVE_MDI: '/dev/doctor-approval/approve-mdi',
    DISAPPROVE_MDI: '/dev/doctor-approval/disapprove-mdi'
  }
  
  public static readonly APPROVER_MAINTENANCE = {
    LIST_APPROVER_MAINTENANCE: "/dev/approver-maintenance/list",
    GET_APPROVER_MAINTENANCE: "/dev/approver-maintenance/get",
    UPDATE_APPROVER_MAINTENANCE: "/dev/approver-maintenance/update",
    INSERT_APPROVER_MAINTENANCE: "/dev/approver-maintenance/insert",
    EXPORT_APPROVER_MAINTENANCE: "/dev/approver-maintenance/task-runner"
  }
  public static readonly FILTER_OPTIONS = {
    LIST_APPROVAL_STATUS: "/dev/filter-options/list_approval_status",
    LIST_DISTRICT: "/dev/filter-options/list_district",
    LIST_TERRITORY: "/dev/filter-options/list_territory",
    LIST_REASON: "/dev/filter-options/list_reason",
    LIST_USER: "/dev/filter-options/list_user",
    LIST_APPROVAL_MODULE: "/dev/filter-options/list_approval_module",
    LIST_INSTITUTION: "/dev/filter-options/list_institution",
    LIST_AREA: "/dev/filter-options/list_area",
    LIST_CLASS: "/dev/filter-options/list_class",
    LIST_SPECIALIZATION: "/dev/filter-options/list_specialization",
    GET_DEVPLAN_FILTERS: "/dev/filter-options/get_devplan_filters",
    LIST_ACTIVITIES: 'dev/filter-options/list_off_activities',
  }
  
  public static readonly DEVPLAN = {
    LIST_HEADERS: "/dev/devplan/header",
    LIST_DETAIL: "/dev/devplan/detail",
    GET_DETAIL: "/dev/devplan/detail",
    UPDATE_DETAIL: "/dev/devplan/update_detail",
    INSERT_DEVPLAN: "/dev/devplan/insert_devplan",
    INSERT_DEVPLAN_DETAIL: "/dev/devplan/insert_devplan_detail",
    EMAIL_DEVPLAN: "/dev/devplan/email_devplan",
    LIST_JOINT_CALL_SUMMARIES: "/dev/devplan/joint_call",
    LIST_JOINT_CALL_OVERALL_CATEGORY: "/dev/devplan/joint_call_category"
  }

  public static readonly SO_APPROVAL = {
    LIST: '/dev/sales-order-approval/list',
    MAX_APPROVER: '/dev/sales-order-approval-details/max_approver',
    DETAILS: '/dev/sales-order-approval-details/details',
    CURRENT_LEVEL: '/dev/sales-order-approval-details/current_user_level',
    APPROVE: '/dev/sales-order-approval/approve',
    DISAPPROVE:'/dev/sales-order-approval/disapprove',
    TASK_RUNNER:'/dev/sales-order-approval/task-runner',
    GET_STATUS_FLAGS:'/dev/sales-order-approval-details/get_status_flags'
  }

  public static readonly WEB_TUTORIAL = {
    INSERT: '/dev/web-tutorial/insert',
    GET_MODULES: '/dev/web-tutorial/get_modules',
    ENABLE_DISABLE:'/dev/web-tutorial/enable_disable',
    GET_TUTORIALS: '/dev/web-tutorial/get_tutorials',
    GET_ENABLED_TUTORIALS: '/dev/web-tutorial/get_enabled_tutorials',
    DELETE_TUTORIAL:'/dev/web-tutorial/delete_tutorial'
  }

  public static readonly USER_MAINTENANCE = {
    GET_LIST: 'dev/user-maintenance/list-all-user',
    GET_USER: 'dev/user-maintenance/search-user',
    UPDATE_USER: 'dev/user-maintenance/update-user',
    GET_MANAGERS: 'dev/user-maintenance/list-managers',
    UPDATE_MUDA: 'dev/user-maintenance/update-muda',
    ADD_USER_INFO: 'dev/user-maintenance/add-user-info',
    ADD_USER_TRANSACTION: 'dev/user-maintenance/add-user-transactions',
    RESET_INSTALLATION_ID: 'dev/user-maintenance/reset-installation-id',
    EXPORT_REPORT: 'dev/user-maintenance/task-runner',
    DISABLE_USER: 'dev/cognito-admin-functions/disable-user',
    ADD_NEW_USER: 'dev/cognito-admin-functions/add-new-user',
    ENABLE_USER: 'dev/cognito-admin-functions/enable-user',
    RESET_USER_PASSWORD: 'dev/cognito-admin-functions/reset-user-password',
    UPDATE_USER_STATUS: 'dev/user-maintenance/update-user-status',
  }

  public static readonly DYNAMIC_SELECTIONS = {
    GET_ROLES: 'dev/dynamic-selections/list-all-roles',
    GET_USER_TYPES: 'dev/dynamic-selections/list-all-user-type',
    GET_EVAL_HEADERS: 'dev/dynamic-selections/list-all-eval-headers',
    GET_ASSOCIATED_DISTRICT: 'dev/dynamic-selections/get-associated-district',
    GET_UNOCCUPIED_DISTRICT: 'dev/dynamic-selections/list_unoccupied-district',
    GET_UNOCCUPIED_TERRITORY: 'dev/dynamic-selections/list-unoccupied-territory',
    GET_UNOCCUPIED_AREA: 'dev/dynamic-selections/list-unoccupied-area',
  }

  public static readonly BROADCAST_MESSAGE = {
    INSERT: '/dev/broadcast-message'
  }
}