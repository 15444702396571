import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private key: CryptoKey | null = null;

  constructor() {
    this.importStoredKey();
  }

  // async generateKey() {
  //   // Generate a new AES-GCM key
  //   this.key = await crypto.subtle.generateKey(
  //     {
  //       name: "AES-GCM",
  //       length: 256,
  //     },
  //     true,
  //     ["encrypt", "decrypt"]
  //   );

  //   return this.key
  // }

  private async importStoredKey(): Promise<void> {
    const keyData = Uint8Array.from(atob(environment.aesKey), c => c.charCodeAt(0)); // Decode base64 to bytes
    this.key = await crypto.subtle.importKey(
      "raw",
      keyData,
      { name: "AES-GCM" },
      true,
      ["encrypt", "decrypt"]
    );
  }

  async encryptData(data: string): Promise<ArrayBuffer> {
    if (!this.key) {
      throw new Error("Key not generated. Call generateKey() first.");
    }
    const encodedData = new TextEncoder().encode(data);
    const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization vector

    const encryptedData = await crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      this.key,
      encodedData
    );

    // Return both the IV and encrypted data
    return new Uint8Array([...iv, ...new Uint8Array(encryptedData)]);
  }

  async decryptData(encryptedData: ArrayBuffer): Promise<string> {
    if (!this.key) {
      throw new Error("Key not generated. Call generateKey() first.");
    }
    
    // Split the IV and actual encrypted data
    const dataArray = new Uint8Array(encryptedData);
    const iv = dataArray.slice(0, 12);
    const actualData = dataArray.slice(12);

    const decryptedData = await crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      this.key,
      actualData
    );

    return new TextDecoder().decode(decryptedData);
  }

  async encryptDataBased(data: string): Promise<string> {
    if (!this.key) {
        throw new Error("Key not generated. Call generateKey() first.");
    }
    const encodedData = new TextEncoder().encode(data);
    const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization vector

    const encryptedData = await crypto.subtle.encrypt(
        {
            name: "AES-GCM",
            iv: iv,
        },
        this.key,
        encodedData
    );

    // Combine IV and encrypted data
    const combinedData = new Uint8Array([...iv, ...new Uint8Array(encryptedData)]);

    // Convert combined data to Base64
    return this.arrayBufferToBase64(combinedData.buffer);
}

async decryptDataBased(base64Data: string): Promise<string> {
  if (!this.key) {
      throw new Error("Key not generated. Call generateKey() first.");
  }

  // Decode Base64 string to ArrayBuffer
  const dataBuffer = this.base64ToArrayBuffer(base64Data);
  const dataArray = new Uint8Array(dataBuffer);

  // Split the IV and actual encrypted data
  const iv = dataArray.slice(0, 12); // First 12 bytes for IV
  const actualData = dataArray.slice(12); // Remaining bytes for encrypted data

  const decryptedData = await crypto.subtle.decrypt(
      {
          name: "AES-GCM",
          iv: iv,
      },
      this.key,
      actualData
  );

  return new TextDecoder().decode(decryptedData);
}

private arrayBufferToBase64(buffer: ArrayBuffer): string {
  const byteArray = new Uint8Array(buffer);
  let binaryString = '';
  byteArray.forEach((byte) => {
      binaryString += String.fromCharCode(byte);
  });
  return btoa(binaryString);
}

private base64ToArrayBuffer(base64: string): ArrayBuffer {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

}
